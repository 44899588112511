import axios from "axios";
import { getFromCookie, setUpCookie } from '../utils/Cookie'
import { TOKEN } from "../utils/Constant";
import { toast } from "react-toastify";


const AxiosConfig = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    headers: {
        "Content-Type": "application/json",
    }
})
AxiosConfig.interceptors.request.use(config => {
    const AuthToken =  getFromCookie('token');
    if (AuthToken) {
        config.headers["Authorization"] = `Bearer ${AuthToken}`
    } else {
        window.location.replace('/login')
    }
    return config

}, error => {
    return Promise.reject(error)
})

AxiosConfig.interceptors.response.use(
    response => {
        if (response.status === 200) {
            if (response.data.code === 403) {
                setUpCookie(TOKEN, '')
                toast.warn('Session timed out. Login again', {
                    onClose: () => window.location.replace('/login'),
                })
            }
        }
        return response;
    },
    error => {
       
        return Promise.reject(error)
    }
)

export { AxiosConfig };
