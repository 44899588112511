import { AxiosConfig } from "./AxiosConfig"

export const getAllUsers = async (params) => {
    return await AxiosConfig.post('/users/getAllUser', null, {
        params: {
            ...params
        }
    })
}

export const getUser = async () => {
    return await AxiosConfig.post('/users/getUser')
}

export const editUser = async (data) => {
    return await AxiosConfig.post('/users/editUser', data)
}

export const resetPassword = async (data) => {
    return await AxiosConfig.post('/users/resetPassword', data)
}