import { useState } from "react"
import { Link } from "react-router-dom"

const CreateTransaction = () => {
  const [transactionID, setTransactionID] = useState("")
  const [amount, setAmount] = useState("")
  const [paymentMode, setPaymentMode] = useState("")
  const [date, setDate] = useState("")
  const [status, setStatus] = useState("")
  const [errors, setErrors] = useState(false)

  const handleSubmit = () => {
    if (transactionID.length === 0 || amount.length === 0 || paymentMode.length === 0 || date.length === 0 || status.length === 0) {
      return setErrors(true)
    }
  }

  return (
    <div className='px-4 py-8 sm:px-9'>
      <h4 className='text-sm text-dark-brown font-inter font-bold'>Transaction</h4>
      <div className='mt-3 w-full bg-white rounded shadow-lg'>
        <div className='px-6 py-8 sm:px-12'>
          <p className='font-inter font-semibold text-xs'>Add Details</p>
          <div className='mt-7 grid grid-cols-1 gap-6 lg:grid-cols-4'>
            <div className='flex-1'>
              <label className='block text-xs font-inter font-semibold mb-2'>Transaction ID</label>
              <input type='text' className={`w-full appearance-none ${errors && !transactionID ? 'border border-red-600' : 'border'} py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none rounded-md`} placeholder='Transaction ID' onChange={(e) => setTransactionID(e.target.value)}/>
            </div>
            <div className='flex-1'>
              <label className='block text-xs font-inter font-semibold mb-2'>Amount</label>
              <input type='number' className={`w-full appearance-none ${errors && !amount ? 'border border-red-600' : 'border'} py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none rounded-md`} placeholder='Amount' onChange={(e) => setAmount(e.target.value)}/>
            </div>
            <div className='flex-1'>
              <label className='block text-xs font-inter font-semibold mb-2'>Payment Mode</label>
              <select className={`w-full ${errors && !paymentMode ? 'border border-red-600' : 'border'} py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none rounded-md`} onChange={(e) => setPaymentMode(e.target.value)}>
                <option defaultChecked>Select Payment Mode</option>
                <option>UPI</option>
              </select>
            </div>
            <div className='flex-1'>
              <label className='block text-xs font-inter font-semibold mb-2'>Transaction Date</label>
              <input type='date' className={`w-full appearance-none ${errors && !date ? 'border border-red-600' : 'border'} py-2 px-3 text-gray-700 text-xs focus:outline-none rounded-md`} onChange={(e) => setDate(e.target.value)}/>
            </div>
          </div>
          <div className='mt-6'>
            <label className='block text-xs font-inter font-semibold mb-2'>Status</label>
            <select className={`w-52 ${errors && !status ? 'border border-red-600' : 'border'} py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none rounded-md`} onChange={(e) => setStatus(e.target.value)}>
              <option defaultChecked>Select Status</option>
              <option>Success</option>
            </select>
          </div>
        </div>
        <div className='flex justify-end gap-6 px-6 sm:px-12 py-4 pb-20'>
          <Link to='/transactions'><button className='text-xs text-gray-700 font-inter font-semibold'>Close</button></Link>
          <button className='text-xs text-white font-inter font-semibold bg-dark-brown py-2 px-4 rounded shadow' onClick={handleSubmit}>Add Transaction</button>
        </div>
      </div>
    </div>
  );
};

export default CreateTransaction