import axios, {Axios} from "axios";
import { AxiosConfig } from "./AxiosConfig";
import { getFromCookie } from "../utils/Cookie";

export const getAllProducts = async () => {
    return await AxiosConfig.post('/products/getCategory')
}

export const getAllSubCategories = async (id) => {
    return await AxiosConfig.post('products/getSubCategory', {
        id: id
    })
}

export const getAllProductCategories = async (id) => {
    return await AxiosConfig.post('products/getProductCategory', {
        id: id
    })
}
export const getAllAddedProducts = async () => {
    return await AxiosConfig.post("/products/getAllProducts", {});
  };
  

export const addCategoryApi = async (bodyData) => {
    return await AxiosConfig.post('products/addCategory',bodyData,{
        headers: {
            'Content-Type' : 'multipart/form-data'
        }
       
    })
}

export const addsubCategoryApi = async(bodyData) => {
    return await AxiosConfig.post('/products/addSubCategory', bodyData, {
        headers: {
            'Content-Type' : 'multipart/form-data'
        }
    })
}

export const addproductCategoryApi = async(bodyData) => {
    return await AxiosConfig.post('products/addProductCategory', bodyData, {
        headers: {
            'Content-Type' : 'multipart/form-data'
        }
    }
    
    )
}


export const getProductBrandsApi = async(bodyData) => {
    return await AxiosConfig.post('Brand/getBrands',
        bodyData
    )
}

export const getProductColorApi = async(bodyData) => {
    return await AxiosConfig.post('Color/getColors', 
        bodyData
    )
}

export const getProductSizeApi = async(bodyData) => {
    return await AxiosConfig.post('ProductSize/getProductSize', 
        bodyData
    )
}

export const getProductUnitApi = async(bodyData) => {
    return await AxiosConfig.post('Unit/getUnits', 
        bodyData
    )
}

export const addProduts = async(bodyData) => {
    return await AxiosConfig.post('products/addProduct', 
        bodyData
    )
}

export const addProductColor = async(bodyData) => {
    return await AxiosConfig.post('Color/addColor',bodyData,{
        headers: {
            'Content-Type' : 'multipart/form-data'
        }
    })
}

export const addProductSize = async(bodyData) => {
    return await AxiosConfig.post('ProductSize/addProductSize', 
        bodyData
    )
}

export const addProductUnit = async(bodyData) => {
    return await AxiosConfig.post('Unit/addUnit',
        bodyData
    )
}

export const getAllBrands = async () => {
    return await AxiosConfig.post('/Brand/getBrands');
}

export const updateBrands = async (data) => {
    return await AxiosConfig.post('/Brand/editBrand', data, {
        headers: {
            'Content-Type' : 'multipart/form-data'
        }
    })
}

  export const addAllBrands = async (data) => {    
      const response = await AxiosConfig.post('/Brand/addBrand', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;   
  };

export const deleteBrands = async (id) => {
    return await AxiosConfig.post('/Brand/deleteBrand', {
        id: id
    })
}
