import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addAllBrands } from "../../Networking/ProductApisActions";
import Loader from "../../acsComponents/Loader";
import Dropzone from './Dropzone';
import { toast } from "react-toastify";
import { setUpCookie } from "../../utils/Cookie";
import { TOKEN } from "../../utils/Constant";

const AddBrand = () => {

  const [brandName, setBrandName] = useState("");
  const [errors, setErrors] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFileChange = (file) => {
    setSelectedFile(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!brandName || !selectedFile) {
      setErrors(true);
      return;
    }

    const formData = new FormData();
    formData.append("brandName", brandName);
    if (selectedFile) {
      formData.append("files", selectedFile);
    }
    setLoading(true);

    addAllBrands(formData).then(res => {
      if (res.data.code === 200) {
        if (res.data.accessToken) setUpCookie(TOKEN, res.data.accessToken);
        toast.success(res.data.message, {
          onClose: () => navigate('/brands'),
        });
        setLoading(false);
      } else {
        toast.error(res.data.message)
        setLoading(false)
      }
    }).catch(() => setLoading(false));
  };

  return (
      <div className="px-10 py-16 h-screen overflow-auto no-scrollbar">
      {loading && <Loader backgroundColor='transparent' />}
        <div className="bg-white shadow-lg rounded">
          <div className="px-20 py-9">
            <h3 className="text-xs font-inter font-semibold">Create Brand Profile</h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <div className="mt-4">
                <label className="block mb-2 text-xs font-inter font-semibold">Brand Name</label>
                <input type="text" className={`w-full appearance-none ${errors && !brandName ? "border border-red-600": "border"} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} placeholder="Enter Brand Name" value={brandName} onChange={(e) => setBrandName(e.target.value)} />
              </div>
            </div>
            <div className="grid grid-cols-1 mt-8">
              <label className="block mb-2 text-xs font-inter font-semibold">Brand Image</label>
              <Dropzone Onfilechange={handleFileChange} className={`px-5 py-3 border border-neutral-200 cursor-pointer ${errors && !selectedFile ? "border border-red-600": "border"}`} /> 
            </div>
            <div className="flex justify-end gap-4 mt-2 pb-8">
              <button className="text-gray-500 text-xs font-inter font-semibold" onClick={() => navigate("/brands")}>Cancel</button>
              <button className="bg-dark-brown px-4 py-1.5 text-xs font-inter text-white rounded" onClick={handleSubmit}>Add Brand</button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default AddBrand;
