import React, { createContext, useState } from "react";

const ProductStates = createContext();

const ProductContext = (props) => {
  const [categoryList, setAllCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [getBrandList, setGetBrandList] = useState([]);
  const [editedBrandId, setEditedBrandId] = useState(0);
  const [addbrandItem, setAddbrandItem] = useState([]);
  const [productBrands, setProductBrands] = useState([]);
  const [productColor, setProductColor] = useState([]);
  const [productSize, setProductSize] = useState([]);
  const [productUnit, setProductUnit] = useState([]);
  const [addColorList, setAddColorList] = useState([]);
  const [getproducts, setGetproducts] = useState([]);
  const [getImageFile, setGetImageFile] = useState(null);

  const updateCategory = (updatedProductCategory) => {
    setAllCategoryList(updatedProductCategory);
  };

  const updateSubCategoryList = (updatedList) => {
    setSubCategoryList(updatedList);
  };

  const updateProductCategory = (updatedList) => {
    setProductCategoryList(updatedList);
  };

  const Brandlist = (updatedBrandList) => {
    setGetBrandList(updatedBrandList);
  };

  const editBrandId = (updatedEditBrandId) => {
    setEditedBrandId(updatedEditBrandId);
  };

  const addBrand = (updateAddBrand) => {
    setAddbrandItem((prevItems) => [...prevItems, updateAddBrand]);
  };

  const productBrandList = (updateBrand) => {
    setProductBrands(updateBrand);
  };

  const productColorList = (updateColor) => {
    setProductColor(updateColor);
  };

  const productSizeList = (updatesize) => {
    setProductSize(updatesize);
  };

  const productUnitList = (updateUnit) => {
    setProductUnit(updateUnit);
  };

  const updateColor = (updateColor) => {
    setAddColorList(updateColor);
  };

  const getProductList = (updateList) => {
    setGetproducts(updateList);
  };

  //brand add image 
  const getBrandImage = ()=>{}

  return (
    <ProductStates.Provider
      value={{
        categoryList: categoryList,
        updateCategory: updateCategory,
        updateSubCategoryList: updateSubCategoryList,
        subCategoryList: subCategoryList,
        productCategoryList: productCategoryList,
        updateProductCategory: updateProductCategory,
        Brandlist: Brandlist,
        getBrandList: getBrandList,
        editBrandId: editBrandId,
        editedBrandId: editedBrandId,
        addBrand: addBrand,
        addbrandItem: addbrandItem,
        productBrands: productBrands,
        productBrandList: productBrandList,
        productColor: productColor,
        productColorList: productColorList,
        productSize: productSize,
        productSizeList: productSizeList,
        productUnit: productUnit,
        productUnitList: productUnitList,
        updateColor: updateColor,
        addColorList: addColorList,
        getProductList: getProductList,
        getproducts: getproducts,
      }}
    >
      {props.children}
    </ProductStates.Provider>
  );
};

export { ProductContext, ProductStates };
