import { createContext, useState } from "react";

const CouponStates = createContext();

const CouponContext = (props) => {
    const [couponList, setCouponList] = useState([]);

    const updateCouponList = (updatedCoupon) => {
        setCouponList(updatedCoupon);
    }

    return (
        <CouponStates.Provider value={{ couponList, updateCouponList }}>
            {props.children}
        </CouponStates.Provider>
    );
};

export { CouponStates, CouponContext };
