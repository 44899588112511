import { useContext } from "react";
import { TOKEN } from "../../utils/Constant";
import { setUpCookie } from "../../utils/Cookie";
import { AuthContext } from "../../Context/AuthUserContext";
import { toast } from "react-toastify";
import { FiLogOut } from "react-icons/fi";

const Logout = () => {

    const { setIsAuthenticated } = useContext(AuthContext);

    const Logout = () => {
        setUpCookie(TOKEN, "");
        setIsAuthenticated(false);
        toast.success("Successfully logged out");
    };

    return (
        <div className="h-[80vh] flex justify-center items-center">
            <button
                onClick={Logout}
                className={`
                    relative z-0 flex items-center gap-2 overflow-hidden rounded-lg border-[1px] 
                    border-dark-brown-70-opacity px-4 py-2 font-semibold
                    uppercase text-dark-brown transition-all duration-500
                    
                    before:absolute before:inset-0
                    before:-z-10 before:translate-x-[150%]
                    before:translate-y-[150%] before:scale-[2.5]
                    before:rounded-[100%] before:bg-dark-brown
                    before:transition-transform before:duration-1000
                    before:content-[""]

                    hover:scale-105 hover:text-white
                    hover:before:translate-x-[0%]
                    hover:before:translate-y-[0%]
                    active:scale-95`
                }
            >
                <FiLogOut />
                <span>Logout</span>
            </button>

        </div>
    );
};

export default Logout;