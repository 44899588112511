import { useState } from "react";
import { addProductColor } from "../../Networking/ProductApisActions";
import { useNavigate } from "react-router-dom";
import Loader from '../../acsComponents/Loader'
import { toast } from "react-toastify";
import { setUpCookie } from "../../utils/Cookie";
import { TOKEN } from "../../utils/Constant";

const AddProductColor = () => {
  const navigate = useNavigate();
  const [colorCode, setColorCode] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let formErrors = {};
    if (!color) {
        formErrors.color = "Colour is required.";
    } 
    if (!colorCode) {
        formErrors.colorCode = "Colour code is required.";
    }
    setErrors(formErrors);
      return Object.keys(formErrors).length === 0;
  }   

  const handleColorSubmit =  (e) => {
    setLoading(true)
    e.preventDefault();
    if (!validateForm()) return; 
    const productData = {
      color: color,
      colorCode: colorCode,
    };
    const refreshData = () => {
      setColor('')
      setColorCode('')
    }
    addProductColor(productData).then((response) => {
      if (response.data.code === 200) {
        if (response.data.acessToken) setUpCookie(TOKEN, response.data.acessToken);
        toast.success(response.data.message);
        refreshData();
        setLoading(false);
      } else {
        toast.error(response.data.message);
        setLoading(false);
      }  
    })
  };

  return (
    <div className="overflow-y-auto no-scrollbar h-screen font-inter">
     {loading && <Loader backgroundColor='transparent' />}
      <div className="flex justify-center items-center w-full h-screen">
        <div className="w-96 h-auto">
          <div className="flex justify-between w-full pl-2 pr-2 pb-2">
            <p className="text-dark-brown">Add Product Color</p>           
          </div>
          <div className="w-full h-auto bg-white p-6">
            <form onSubmit={handleColorSubmit}>
              <label>Colour Code</label>
              <input
                type="text"
                className="w-full p-2 border outline-none mb-2"
                name="colorCode"
                onChange={(e) => setColorCode(e.target.value)}
                value={colorCode}
              />
              {errors.colorCode && <p className='text-xs text-red-600'>{errors.colorCode}</p>}
              <label>Colour</label>
              <input
                type="text"
                className="w-full p-2 border outline-none"
                name="color"
                onChange={(e) => setColor(e.target.value)}
                value={color}                
              />    
             {errors.color && <p className='text-xs text-red-600'>{errors.color}</p>}         
               <button
                type="submit"
                className="bg-darkbrown border px-3 py-1 mt-8 bg-dark-brown text-white"
              >
                Save
              </button>
              <button
                type="button"
                className="border-2 ml-2 px-3 py-1 hover:bg-dark-brown hover:text-white rounded-sm cursor-pointer"
                onClick={() => {navigate(-1)}}
              > Cancel </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddProductColor;
