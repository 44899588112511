import { useContext, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Dropzone from './Dropzone'
import Loader from "../../acsComponents/Loader"
import { CouponStates } from "../../Context/CouponContext"
import { editcoupon } from "../../Networking/CouponAction"
import { TOKEN } from "../../utils/Constant"
import { setUpCookie } from "../../utils/Cookie"
import { toast } from "react-toastify"

const EditCoupon = () => {

    const [couponTitle, setCouponTitle] = useState("")
    const [couponCode, setCouponCode] = useState("")
    const [startDate, setStartDate] = useState("")
    const [dueDate, setDueDate] = useState("")
    const [amount, setAmount] = useState("")
    const [errors, setErrors] = useState(false)
    const [description, setDescription] = useState("")
    const [image, setImage] = useState("")
    const [maxDiscount, setMaxDiscount] = useState("")
    const [minAmount, setMinAmount] = useState("")
    const [noOfTimes, setNoOfTimes] = useState("")
    const [loading, setLoading] = useState(false)

    const couponContext = useContext(CouponStates)
    const navigate = useNavigate()
    const location = useLocation()
    const { id } = location.state

    const convertYMD = (date) => {
        const [ day, month, year ] = date.split('/')
        return `${year}-${month}-${day}`
    }

    const convertDMY = (date) => {
        const [ year, month, day ] = date.split('-')
        return `${day}-${month}-${year}`
    }

    const couponData = couponContext.couponList.find(item => item.id === id)

    useEffect(() => {
        if(couponData) {
            setCouponTitle(couponData.offerName)
            setCouponCode(couponData.couponCode)
            setStartDate(convertYMD(couponData.startDate))
            setDueDate(convertYMD(couponData.endDate))
            setAmount(couponData.discount)
            setDescription(couponData.description)
            setMaxDiscount(couponData.maximumDiscount)
            setMinAmount(couponData.minimumPurchaseAmount)
            setNoOfTimes(couponData.noOfTimes)
            setImage(couponData.couponImage)
            setLoading(false)
        } else {
            navigate('/coupons')
        }
    }, [id])    

    const handleFile = (url) => setImage(url)

    const hanldeSubmit = () => {
        if(couponTitle.length === 0 || couponCode.length === 0 || startDate.length === 0 || dueDate.length === 0 || amount.length === 0 || maxDiscount.length === 0 || description.length === 0 || minAmount.length === 0 || noOfTimes.length === 0 || image.length) {
          return setErrors(true)
        }

        const bodyData = new FormData()
        bodyData.append('file', image)

        const params = {
            id: id,
            couponCode: couponCode,
            offerName: couponTitle,
            description: description,
            discount: amount,
            maximumDiscount: maxDiscount,
            minimumPurchaseAmount: minAmount,
            startDate: convertDMY(startDate),
            endDate: convertDMY(dueDate),
            noOfTimes: noOfTimes,
            bankOffer: false,
            active: true,
        }

        setLoading(true)
        
        editcoupon(bodyData, params).then(res => {
            if (res.data.code === 200) {
                if (res.data.accessToken) setUpCookie(TOKEN, res.data.accessToken)
                toast.success(res.data.message, {
                    onClose: () => navigate('/coupons')
                })
                setLoading(false)
            } else {
                toast.error(res.data.message)
                setLoading(false)
            }
        }).catch(() => setLoading(false))
    }

  return (
    <div className='px-4 py-14 sm:px-9 h-screen overflow-auto no-scrollbar'>
        {loading && <Loader backgroundColor='transparent' />}
        <div className='mt-3 w-full bg-white rounded shadow-lg h-500px no-scrollbar overflow-y-scroll'>
            <div className='px-20 py-8'>
                <p className='font-inter font-semibold text-xs'>Edit Coupon</p>
                    <div className='mt-5 grid grid-cols-1 gap-6 lg:grid-cols-2 mb-4'>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>Coupon Title</label>
                            <input type='text' value={couponTitle} className={`w-full appearance-none ${errors && !couponTitle ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} placeholder='Coupon Title' onChange={(e) => setCouponTitle(e.target.value)} />
                        </div>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>Coupon Code</label>
                            <input type='text' value={couponCode} className={`w-full appearance-none ${errors && !couponCode ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} placeholder='Enter Coupon Code' onChange={(e) => setCouponCode(e.target.value)} />
                        </div>
                    </div>

                    <div className='mt-5 grid grid-cols-1 lg:grid-cols-4 gap-4'>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>Description</label>
                            <textarea value={description} className={`w-full appearance-none ${errors && !description ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} placeholder='Enter Description' onChange={(e) => setDescription(e.target.value)} />
                        </div>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>Start Date</label>
                            <input type='date' value={startDate} className={`w-full ${errors && !startDate ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none cursor-pointer`} onChange={(e) => (setStartDate(e.target.value))} />
                        </div>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>End Date</label>
                            <input type='date' value={dueDate} className={`w-full ${errors && !dueDate ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none cursor-pointer`} onChange={(e) => setDueDate(e.target.value)} />
                        </div>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>Discount (%)</label>
                            <input type='number' value={amount} className={`w-full appearance-none ${errors && !amount ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} placeholder='Enter Percentage' onChange={(e) => setAmount(e.target.value)}/>
                        </div>
                    </div>

                    <div className='mt-5 grid grid-cols-1 lg:grid-cols-4 gap-4'>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>Minimum Amount</label>
                            <input type='number' value={minAmount} className={`w-full ${errors && !minAmount ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} onChange={(e) => setMinAmount(e.target.value)} />
                        </div>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>Maximum Amount</label>
                            <input type='number' value={maxDiscount} className={`w-full ${errors && !maxDiscount ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} onChange={(e) => setMaxDiscount(e.target.value)} />
                        </div>
                        <div className='lg:col-span-2'>
                            <label className='block font-inter font-semibold text-xs mb-2'>Coupon Image</label>
                            <Dropzone className={`py-4 px-2 border ${errors && !image ? 'border-red-500' : 'border-neutral-200'} cursor-pointer`} OnFilechange={handleFile} defaultImage={image} />
                        </div>           
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-4'>
                        <div>
                            <label className='block font-inter font-semibold text-xs mb-2'>No Of Times</label>
                            <input type='number' value={noOfTimes} className={`w-full ${errors && !noOfTimes ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`} onChange={(e) => setNoOfTimes(e.target.value)}/>
                        </div>
                    </div>

                    <div className='mt-2 flex justify-end gap-6 pb-4'>
                        <Link to='/coupons'><button className='text-xs text-gray-700 font-inter font-semibold'>Close</button></Link>
                        <button className='text-xs text-white font-inter font-semibold bg-dark-brown w-28 h-9 rounded border' onClick={hanldeSubmit}>Update Coupon</button>
                    </div>
            </div>

        </div>               
    </div>
  )
}

export default EditCoupon