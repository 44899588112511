import { useState } from 'react'
import { Share, Trashcan } from '../../assets/icons'
import Typeahead from './Typeahead'

const CreateInvoice = () => {
  const [items, setItems] = useState([{ id: Date.now() }])
  const [allItemsData, setAllItemsData] = useState([])
  const [subtotal, setSubTotal] = useState(0)
  const [totalDiscount, setTotalDiscount] = useState(0)
  const [totalTax, setTotalTax] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)


  const fetchSuggestions = async (query) => {
    const response = await fetch("https://jsonplaceholder.typicode.com/users")
    const data = await response.json()
    return data.map(user => user.name).filter(name => name.toLowerCase().includes(query.toLowerCase()))
  }

  const itemChange = (allItems) => {
    setAllItemsData(allItems)

    let totalAmount
    let totalDiscount
    let beforeTaxAmount
    let taxAmount

    totalAmount = allItems.reduce(function (total, item) {
      let totalAmount = (!isNaN(item.rate) ? item.rate : 0) * (!isNaN(item.quantity) ? item.quantity : 0)
      return total + totalAmount
    }, 0) // subtotal

    totalDiscount = allItems.reduce(function (total, item) {
      let totalAmount = (!isNaN(item.rate) ? item.rate : 0) * (!isNaN(item.quantity) ? item.quantity : 0)
      let discountAmount = (!isNaN(item.discount) ? (item.discount / 100) * totalAmount : 0)
      return total + discountAmount
    }, 0) // discount

    beforeTaxAmount = allItems.reduce(function (total, item) {
      let totalAmount = (!isNaN(item.rate) ? item.rate : 0) * (!isNaN(item.quantity) ? item.quantity : 0)
      let discountAmount = (!isNaN(item.discount) ? (item.discount / 100) * totalAmount : 0)
      let rateWithoutTax = totalAmount - discountAmount
      return total + rateWithoutTax
    }, 0) // rate without tax

    taxAmount = allItems.reduce(function (total, item) {
      let totalAmount = (!isNaN(item.rate) ? item.rate : 0) * (!isNaN(item.quantity) ? item.quantity : 0)
      let discountAmount = (!isNaN(item.discount) ? (item.discount / 100) * totalAmount : 0)
      let tax = (!isNaN(item.tax) ? (totalAmount - discountAmount) * (item.tax / 100) : 0)
      return total + tax
    }, 0) // tax amount

    setSubTotal(totalAmount.toFixed(2))
    setTotalDiscount(totalDiscount.toFixed(2))
    setTotalTax(taxAmount.toFixed(2))
    setTotalAmount((beforeTaxAmount + taxAmount).toFixed(2))
  }

  const showTotalAmount = (item) => {
    let totalAmount
    if (item.quantity && !isNaN(item.quantity)) {
      totalAmount = (!isNaN(item.rate) ? item.rate : 0) * (!isNaN(item.quantity) ? item.quantity : 0)
    } else {
      totalAmount = 0
    }

    let totalDiscount
    if (item.discount && !isNaN(item.discount)) {
      totalDiscount = (item.discount / 100) * totalAmount
    } else {
      totalDiscount = 0
    }

    let taxAmount
    if (item.tax && !isNaN(item.tax)) {
      taxAmount = (item.tax / 100) * (totalAmount - totalDiscount).toFixed(2)
    } else {
      taxAmount = 0
    }

    return (totalAmount - totalDiscount + parseFloat(taxAmount)).toFixed(2)
  }

  const handleRateChange = (value, index) => {
    const updatedItems = items.map((item, itemIndex) => {
      if (itemIndex === index) {
        return {...item, rate: value}
      }
      return item
    })
    setItems(updatedItems)
    itemChange(updatedItems)
  }

  const hanldeQuantityChange = (value, index) => {
    const updatedItems = items.map((item, itemIndex) => {
      if (itemIndex === index) {
        return {...item, quantity: value}
      }
      return item
    })
    setItems(updatedItems)
    itemChange(updatedItems)
  }

  const handleDiscountChange = (value, index) => {
    const updatedItems = items.map((item, itemIndex) => {
      if (itemIndex === index) {
        return {...item, discount: value}
      }
      return item
    })
    setItems(updatedItems)
    itemChange(updatedItems)
  }

  const handleTaxChange = (value, index) => {
    const updatedItems = items.map((item, itemIndex) => {
      if (itemIndex === index) {
        return {...item, tax: value}
      }
      return item
    })
    setItems(updatedItems)
    itemChange(updatedItems)
  }

  const addRow = () => {
    setItems([...items, { id: Date.now() }])
  } // id -> for now 

  const deleteRow = (id) => {
   const updatedItems = items.filter(item => item.id !== id)
   setItems(updatedItems)
   itemChange(updatedItems)
  }

  const handleSave = () => {
    console.log("success",allItemsData);
  }

  return (
    <div className='px-9 py-3'>
      <div className='grid grid-cols-1 lg:grid-cols-4 gap-12'>
      {/* -----------------------Table--------------------------------------   */}
        <div className='col-span-3 bg-white shadow rounded pr-5 pl-9 py-1'>
          <div className='mt-4 p-3 bg-whitesmoke rounded shadow-lg flex justify-between'>
            <div className='pb-1'>
              <h5 className='font-inter font-semibold text-sm'>LUXORÉ DELIGHTS</h5>
              <p className='mt-1 font-inter text-xs text-gray-500'>Eraviputhurkadai, Kannyakumari Dist,</p>
              <p className='font-inter text-xs text-gray-500'>Tamil Nadu, India</p>
              <p className='font-inter text-xs text-gray-500'>+91 73739 32235, +91 94880 68189</p>          
            </div>
            <div className='flex items-center gap-3'>
              <label className='text-sm text-gray-500'>Date issued</label>
              <input type='date' className='appearance-none border py-0.5 px-0.5 text-gray-700 text-xs font-inter focus:outline-none cursor-pointer'/>
            </div>
          </div>

          <div className='mt-7 grid grid-cols-4 mb-4'>
            <div className='col-span-3 flex flex-col gap-2'>
              <p className='font-inter font-medium text-sm text-gray-500'>Invoice To:</p>
              <Typeahead fetchSuggestions={fetchSuggestions} />
            </div>
            <div>
              <p className='font-inter font-medium text-xs text-gray-500 mb-2'>Bill To:</p>
              <p className='font-inter text-xs text-gray-500'>12-144 South Street, Main Road,</p>
              <p className='font-inter text-xs text-gray-500'>Near Chennai Cross Street,</p>
              <p className='font-inter text-xs text-gray-500'>Gandhi Nagar, Chennai-600 028</p>
            </div>
          </div>

          <div className='mt-6 mb-5 no-scrollbar max-h-48 overflow-y-auto'>
            <table className='w-full'>
              <thead>
                <tr className='bg-white font-inter font-semibold text-xs text-center sticky top-0 border'>
                  <th className='p-3'>NO</th>
                  <th>PRODUCT DETAILS</th>
                  <th className='p-3'>RATE</th>
                  <th className='p-3'>QUANTITY</th>
                  <th className='p-3'>DISCOUNT(%)</th>
                  <th className='p-3'>TAX(%)</th>
                  <th className='p-3'>AMOUNT</th>
                  <th className='p-3'>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={index} className='font-inter font-medium text-xs text-center border'>
                    <td className='p-3'>{index + 1}</td>
                    <td className='p-3'>
                      <input type='text' className='w-full appearance-none border-b py-1 px-3 text-gray-700 text-xs font-inter focus:outline-none' placeholder='Product...'/>
                    </td>
                    <td className='p-3'>
                      <input type='number' className='w-full appearance-none border-b py-1 px-3 text-gray-700 text-xs font-inter focus:outline-none' placeholder='Price' value={item.rate || ''} onChange={(e) => handleRateChange(e.target.value, index)}/>
                    </td>
                    <td className='p-3'>
                      <input type='number' className='w-full appearance-none border-b py-1 px-3 text-gray-700 text-xs font-inter focus:outline-none' placeholder='Quantity' value={item.quantity || ''} onChange={(e) => hanldeQuantityChange(e.target.value, index)}/>
                    </td>
                    <td className='p-3'>
                      <input type='number' className='w-full appearance-none border-b py-1 px-3 text-gray-700 text-xs font-inter focus:outline-none' placeholder='Discount' value={item.discount || ''} onChange={(e) => handleDiscountChange(e.target.value, index)}/>
                    </td>
                    <td className='p-3'>
                      <input type='number' className='w-full appearance-none border-b py-1 px-3 text-gray-700 text-xs font-inter focus:outline-none' placeholder='Tax' value={item.tax || ''} onChange={(e) => handleTaxChange(e.target.value, index)}/>
                    </td>
                    <td className='p-3'>{showTotalAmount(item)}</td>
                    <td className='p-3 flex justify-center items-center cursor-pointer'><img src={Trashcan} alt='Trash-Can' width={12} height={12} onClick={() => deleteRow(item.id)} /></td>
                  </tr>
                ))}
              </tbody> 
            </table>
          </div>

          <div className='grid grid-cols-4 mb-6'>
            <div className='col-span-3'>
              <button className='text-xs text-white font-inter font-semibold bg-dark-brown py-1.5 px-3 rounded' onClick={addRow}>Add Item</button>
            </div>
            <div className='grid grid-cols-2'>
              <div className='space-y-3'>
                <p className='text-xs text-end font-montserrat font-semibold'>Subtotal:</p>
                <p className='text-xs text-end font-montserrat font-semibold'>Discount:</p>
                <p className='text-xs text-end font-montserrat font-semibold'>Tax:</p>
                <hr/>
                <p className='text-xs text-end font-montserrat font-semibold'>Total:</p>
              </div>           
              <div className='space-y-3'>
                <p className='text-xs text-center font-montserrat font-semibold'>{subtotal ? subtotal : '-'}</p>
                <p className='text-xs text-center font-montserrat font-semibold'>{totalDiscount ? totalDiscount : '-'}</p>
                <p className='text-xs text-center font-montserrat font-semibold'>{totalTax ? totalTax : '-'}</p>
                <hr/>
                <p className='text-xs text-center font-montserrat font-semibold'>&#8377;{totalAmount}</p>
              </div>
            </div>
          </div>

        </div>
      {/* -----------------------buttons------------------------------------ */}
        <div className='bg-white shadow rounded max-h-36 flex flex-col justify-center'>
            <div className='flex flex-col px-9 py-5 gap-5'>
              <button className='bg-dark-brown py-2.5 flex justify-center items-center gap-6 rounded shadow-md'>
                <img src={Share} alt='share' width={15} height={15} />
                <span className='font-inter font-semibold text-xs text-white'>Send Invoice</span>
              </button>
              <button className='border ring-1 ring-gray-400 py-2 rounded shadow-md text-xs font-inter font-semibold' onClick={handleSave}>Save</button>
            </div>
        </div>

      </div>
      
    </div>
  )
}

export default CreateInvoice