import { useContext, useEffect, useState } from "react";
import Pagination from "../../acsComponents/Pagination";
import SearchBar from "../../acsComponents/SearchBar";
import Loader from "../../acsComponents/Loader";
import { getAllTransactions } from "../../Networking/TransactionApiAction";
import { TransactionStates } from "../../Context/TransactionContext";

const Transaction = () => {
  const transactionContext = useContext(TransactionStates);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [expandTable, setExpandTable] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllTransactions().then((response) => {
      setLoading(false);
      const data = response?.data?.data;
      transactionContext.GetTransactionApi(data);
      setFilteredData(data);
    });
  }, []);

  const handleSearch = (value) => setSearchInput(value.toLowerCase());

  useEffect(() => {
    if (transactionContext.data) {
      if (searchInput) {
        const filter = transactionContext.data?.filter((item) => item.orderItems?.orderId?.toLowerCase().includes(searchInput));
        setFilteredData(filter);
      } else {
        setFilteredData(transactionContext.data);
      }
    }
  }, [searchInput, transactionContext.data]);

  const toggleExpandRow = (index) => {
    setExpandTable(expandTable === index ? null : index);
  };

  const tableHead = ["Transaction ID", "Customer Name", "Amount", "Payment Method", "Total Orders", "Order Details"];
  const expandTableHead = ["Order ID", "Product Name", "Price", "Quantity", "Order Status", "Payment Status"];

  return (
      <div className="px-9 py-8 h-screen overflow-auto no-scrollbar">
      {loading && <Loader backgroundColor='transparent' />}
        <h4 className="text-base text-dark-brown font-inter font-bold uppercase">Transaction</h4>
        <div className="mt-3 w-full h-500px bg-white rounded shadow-xl">
          <div className="relative">
            <div className="flex justify-between items-center p-2">
              <div className="flex flex-wrap items-center gap-3 mt-2 ms-5">
                <SearchBar placeholder="Search Transaction ID" onInputChange={handleSearch} />
              </div>
            </div>
            <div className="mt-4 no-scrollbar max-h-96 overflow-y-auto">
              <div className="flex flex-col space-y-2">
                <div className="flex bg-light-brown p-3 font-inter font-semibold text-xs text-center sticky top-0">
                    {tableHead.map((item) => (<div className="flex-1">{item}</div>))}
                </div>
                <Pagination data={filteredData} justifyContent="end">
                  {filteredData?.length > 0 ? (filteredData.map((item, index) => (             
                      <>
                        <div key={index} className="flex border-b border-gray-300">
                          <div className="flex-1 text-center py-3 font-inter text-xs text-gray-500">{item.orderItems?.orderId ? item.orderItems.orderId : "N/A"}</div>
                          <div className="flex-1 text-center py-3 font-inter text-xs text-gray-500">{item.customerName ? item.customerName : "N/A"}</div>
                          <div className="flex-1 text-center py-3 font-inter text-xs text-gray-500">{item.totalAmount ? item.totalAmount : "N/A"}</div>
                          <div className="flex-1 text-center py-3 font-inter text-xs text-gray-500">{item.paymentType ? item.paymentType : "N/A"}</div>
                          <div className="flex-1 text-center py-3 font-inter text-xs text-gray-500">{item.orderItems?.totalOrder ? item.orderItems.totalOrder : "N/A"}</div>        
                          <div className="flex-1 text-center py-3 font-inter text-xs text-dark-brown underline underline-offset-2 cursor-pointer hover:text-gray-400" onClick={() => toggleExpandRow(index)}>
                            {expandTable === index ? "Hide Details" : "View Details"}
                          </div>
                        </div>
                        {expandTable === index && (
                          <div className="flex flex-col bg-gray-100 px-4 py-2 border-b border-gray-300">
                            <div className="flex bg-gray-200 py-2">
                              {expandTableHead.map((items) => (<div key={items} className="flex-1 text-center font-inter text-xs font-semibold text-gray-700">{items}</div>))}
                            </div>
                            {item.orderItems?.listOrderItems && item.orderItems.listOrderItems.length > 0 ? (item.orderItems.listOrderItems.map((orderItem) => (
                                  <div key={orderItem.id} className="flex border-b border-gray-200 py-2">
                                    <div className="flex-1 text-center py-1 font-inter text-xs text-gray-600">{orderItem.orderId}</div>
                                    <div className="flex-1 text-center py-1 font-inter text-xs text-gray-600">{orderItem.productName}</div>
                                    <div className="flex-1 text-center py-1 font-inter text-xs text-gray-600">{orderItem.price}</div>
                                    <div className="flex-1 text-center py-1 font-inter text-xs text-gray-600">{orderItem.quantity}</div>
                                    <div className="flex-1 text-center py-1 font-inter text-xs text-gray-600">{orderItem.orderStatusName}</div>
                                    <div className="flex-1 text-center py-1 font-inter text-xs text-gray-600">{orderItem.paymentStatus}</div>
                                  </div>
                                ))) : (
                                    <div className="flex-1 text-center py-1 font-inter text-xs text-red-600 italic">No data available.</div>    
                            )}
                          </div>
                        )}
                      </>
                    ))
                  ) : (
                    <div className="flex-1 text-center py-3 font-inter font-medium text-sm text-red-600 italic">No data available.</div>
                  )}
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
export default Transaction;
