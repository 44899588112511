import React, {createContext, useEffect, useState} from "react";
import { getFromCookie } from "../utils/Cookie";
import { TOKEN } from "../utils/Constant";


const AuthContext = createContext();

const AuthUser = (props) => {

    const [name, setName] = useState('')
    const [id, setId] = useState(0)
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    useEffect(() => {
        const token = getFromCookie(TOKEN)
        if(token) {
            setIsAuthenticated(true)
        }
    }, [])

    const updateName = (name) => {
        //update Api
        setName(name)

    }

    const updateId = (id) => {
        setId(id)
    }

    return <AuthContext.Provider value={{name: name, updateId: updateId, updateName: updateName, id: id, setIsAuthenticated, isAuthenticated}}>
        {props.children}
    </AuthContext.Provider>
}

export {AuthContext, AuthUser}