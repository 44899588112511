import { useState } from 'react'
import login from '../../assets/login.png'
import { Link } from 'react-router-dom'

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState(false)

    const handleReset = (e) => {
        e.preventDefault()

        if(email.length === 0) {
            return setErrors(true)
        }
    }

  return (
    <div>
        <div className='flex justify-center items-center h-screen bg-cover bg-center' style={{ backgroundImage: `url(${login})` }}>
            <div className='w-1/3 h-80 p-4 rounded bg-white shadow-xl'>
                <p className='mt-2 flex justify-center text-xl text-dark-brown font-inter font-semibold'>Forgot Your Password?</p>
                <div className='p-3'>
                    <form className='mt-5 px-6' onSubmit={handleReset} noValidate>
                        <div className='mb-4'>
                            <label className='block text-sm font-montserrat font-semibold mb-2'>Email ID</label>
                            <input type='email' className='peer w-full border py-2 px-2 appearance-none text-gray-700 text-sm focus:outline-none bg-whitesmoke ... invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500' id='email' placeholder='Your Email Address' pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$' value={email} onChange={(e) => setEmail(e.target.value)} />
                            <span className='hidden text-xs text-red-600 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block'>Please enter a valid email address</span>
                            {errors && !email && (<p className='text-xs text-red-600'>Email Required</p>)}
                        </div>
                        <div className='flex justify-center'>
                            <button className='mt-3 w-60 h-8 bg-dark-brown text-white text-xs font-montserrat font-semibold'>Reset Password</button>
                        </div>
                        <Link to="/login"><p className='mt-4 text-center text-xs text-gray-400 font-montserrat font-semibold'>Back to Sign In</p></Link>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ForgotPassword