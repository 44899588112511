import React, { useEffect, useContext, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  getAllProducts,
  getAllSubCategories,
  getAllProductCategories,
  addCategoryApi,
  addsubCategoryApi,
  addproductCategoryApi,
  addProduts,
  getProductBrandsApi,
  getProductColorApi,
  getProductSizeApi,
  getProductUnitApi
} from "../../Networking/ProductApisActions";
import ListSelection from "../../acsComponents/ListSelection";
import {ProductStates } from "../../Context/ProductContext";
import CheckTick from "../../assets/svg/CheckTick.svg";
import CheckBox from "../../assets/svg/CheckBox.svg";
import uploadIcon from "../../assets/svg/upload.svg";
import Loader from '../../acsComponents/Loader'
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setUpCookie } from "../../utils/Cookie";
import { TOKEN } from "../../utils/Constant";

const addproductData = [
  { id: 1, productData: "Fast Selling" },
  { id: 2, productData: "Trending" },
  { id: 3, productData: "Premium" },
  { id: 4, productData: "Common" },
  { id: 5, productData: "VegProduct" },
];

const AddProducts = () => {
  const navigate = useNavigate()
  const productContext = useContext(ProductStates);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null); 
  const [selectedProductCategory,setSelectedProductCategory] = useState(null)
  const [selectedImages, setSelectedImages] = useState([]);
  const [addCategory, setAddCategory] = useState("");
  const [addSubCategory, setAddSubCategory] = useState("");
  const [addProductCategory, setAddProductCategory] = useState("")
  const [filteredCategoryData, setFilteredCategoryData] = useState([]);
  const [filteredSubCategoryData, setFilteredSubCategoryData] = useState([]);
  const [filteredProductData, setFilteredProductData]  = useState([])
  const [showSubCategory, setShowSubCategory] = useState(true)
  const [showProductCategory, setShowProductCategory] = useState(false)
  const [addProductDetails, setAddProductDetails] = useState(false)
  const [checkedState, setCheckedState] = useState(new Array(addproductData.length).fill(false));
  const [addProductName, setAddProductName] = useState("");
  const [addproductBrand, setAddProductBrand] = useState("");
  const [addProductColour, setAddProductColour] = useState("");
  const [addProductSize, setAddProductSize] = useState("");
  const [addProductUnit, setAddProductUnit] = useState("");
  const [addDiscountPrice, setAddDiscountPrice] = useState("");
  const [addDescription, setAddDescription] = useState("")
  const [addGSTPercentage, setAddGSTPercentage] = useState("")
  const [addMinOrder, setAddMinOrder] = useState("")
  const [mainImg, setMainImg] = useState("")
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [unitPrice, setUnitPrice] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState('');

  const onEditorStateChange = useCallback((newEditorState) => {
    setEditorState(newEditorState);
    const html = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    setAddDescription(html); // Update state to store HTML content
  }, []);

  const validateForm = () => {
    let formErrors = {};
    if (!addProductName) {
        formErrors.addProductName = "Product Name is required.";
    } 
    if (!addproductBrand) {
        formErrors.addproductBrand = "Brand is required.";
    }
    if (!addProductColour) {
      formErrors.addProductColour = "Colour is required.";
    }
    if (!addProductSize) {
      formErrors.addProductSize = "Size is required.";
    }
    if (!addProductUnit) {
      formErrors.addProductUnit = "Unit is required.";
    }
    if (!addGSTPercentage) {
      formErrors.addGSTPercentage = "GST Percentage is required.";
    }
    if (!mainImg) {
      formErrors.mainImg = "Main Image is required.";
    }
    if (!addMinOrder) {
      formErrors.addMinOrder = "Minimum order is required.";
    }
    if (!unitPrice) {
      formErrors.unitPrice = "Price is required."
    }
    if (!addDiscountPrice && addDiscountPrice !== 0) {
      formErrors.addDiscountPrice = "Discount price is required.";
    } else if (addDiscountPrice <= 0) {
      formErrors.addDiscountPrice = "Discount price must be greater than 0.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const getAllCategoriesData = () => {
    setLoading(true)
    getAllProducts().then((response) => {
      const data = response?.data?.data || [];
      productContext.updateCategory(data);
      setFilteredCategoryData(data);
      setLoading(false)
      if (data.length > 0) {
        setSelectedCategory(data[0])
      }
    });
  };

  useEffect(() => {
    getAllCategoriesData();
  }, []);


  const handleCategoryChange = (e) => {
    const value = e.target.value;
    setAddCategory(value);
    if (value) {
      const filteredData = filteredCategoryData.filter((data) =>  data.categoryName?.toLowerCase().includes(value.toLowerCase()));
      setFilteredCategoryData(filteredData);
    } else {
      getAllCategoriesData();
    }
  };

  const handleCategorySubmit = (e) => {  
    e.preventDefault();
    const bodyData = { categoryName: addCategory };
    addCategoryApi(bodyData).then(response => { 
      setLoading(true)
      if(response.data.code === 200) {
        if (response.data.accessToken) setUpCookie(TOKEN, response.data.accessToken)
        toast.success(response.data.message)
        setLoading(false)
        getAllCategoriesData();     
        setAddCategory("");
      } else {
        toast.error(response.data.message)
        setLoading(false)
        getAllCategoriesData();            
      }
    });
  };  

  const handleCategoryClick = (item) => {
    setShowProductCategory(false)
    setAddProductDetails(false)
    setSelectedCategory(item);
    fetchSubCategories(item.id);
    resetValues()
  };  

  const fetchSubCategories = (categoryId) => {
    setLoading(true)
    getAllSubCategories(categoryId).then((result) => {
      const data = result.data.data;
      productContext.updateSubCategoryList(data);
      setFilteredSubCategoryData(data);
      setLoading(false)
    });
  };

  useEffect(() => {
    if (selectedCategory) {
      fetchSubCategories(selectedCategory.id);
    }
  }, [selectedCategory]);

  const handleSubCategoryChange = (e) => {
    const value = e.target.value;
    setAddSubCategory(value);
    if (value) {
      const filteredData = (filteredSubCategoryData || []).filter((data) => data.subCategoryName?.toLowerCase().includes(value.toLowerCase()));
      setFilteredSubCategoryData(filteredData);
    } else {
      if (selectedCategory && selectedCategory.id) {
        fetchSubCategories(selectedCategory.id);
      }
    }
  };

  const handleSubCategorySubmit = (e) => { 
    e.preventDefault();
    if (selectedCategory && addSubCategory) {
      const bodyData = {
        categoryId: selectedCategory.id,
        subCategory: addSubCategory
      };

      addsubCategoryApi(bodyData).then(response => { 
        setLoading(true)    
        if(response.data.code === 200) {
          if (response.data.accessToken) setUpCookie(TOKEN, response.data.accessToken);
          toast.success(response.data.message);
          setLoading(false);
          fetchSubCategories(selectedCategory.id); 
          setAddSubCategory("");       
        } else {
          toast.error(response.data.message)
          setLoading(false)
          fetchSubCategories(selectedCategory.id); 
        }     
      });
    };
  };

  const onSubCategoryClick = (item) => {
    setSelectedSubCategory(item)
    fetchProductCategoryData(item.id)  
    resetValues()
  }

  const fetchProductCategoryData = (productId) => {
    setLoading(true)
    getAllProductCategories(productId).then(
      (response) => {
        const data = response.data.data
        productContext.updateProductCategory(data)
        setFilteredProductData(data)
        setLoading(false)
      }
    )
  }

  useEffect(() => {
    if (selectedSubCategory) {
      fetchProductCategoryData(selectedSubCategory.id)}
  },[selectedSubCategory])

  const handleProductCategoryChange = (e) => {
    const value = e.target.value;
    setAddProductCategory(value); 
    if (value) {
      const filteredData = (filteredProductData || []).filter((data) => data.productCategory?.toLowerCase().includes(value.toLowerCase()));
      setFilteredProductData(filteredData);
    } else {
      if (selectedSubCategory && selectedSubCategory.id) {
        fetchProductCategoryData(selectedSubCategory.id);
      }
    }
  };

  const handleProductCategorySUbmit = (e) => {
    e.preventDefault();
    if (selectedCategory && addProductCategory) {
      const bodyData = {
        productCategory : addProductCategory,
        categoryId : selectedCategory.id,
        subCategoryId : selectedSubCategory.id
      }

      addproductCategoryApi(bodyData).then(response => {
        setLoading(true)
        if(response.data.code === 200) {
          toast.success(response.data.message)
          setLoading(false)
          fetchProductCategoryData(selectedSubCategory.id)
          setAddProductCategory("")
        } else {
          setLoading(false)
          fetchProductCategoryData(selectedSubCategory.id)
        }      
      })
    }
  }

  const handleToggle = (index) => {
    setCheckedState((prevCheckedState) =>
      prevCheckedState.map((item, idx) => (idx === index ? !item : item))
    );
  };

  const handleDecrypt = (base64String) => {
    return `data:image/jpeg;base64,${base64String}`;
  };

  const handleRemoveImage = (indexToRemove) => {
    setSelectedImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const onProductCategorySelect = (item) => {
    setSelectedProductCategory(item);
    resetValues()
  };  

  const fileInputRef = useRef(null);

  const handleMainImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result.split(',')[1];  
        setMainImg(base64String);
        fileInputRef.current.value = '';
      };
      reader.onerror = (error) => {
        console.error(error);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileSelect = (e) => {
    const files = e.target.files;
    const newImages = [];
    for (const file of files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64String = e.target.result.split(',')[1]; 
        newImages.push(base64String);
        setSelectedImages([...selectedImages, ...newImages]);
      };
      reader.onerror = (error) => {
        console.error( error);
      };
      reader.readAsDataURL(file);      
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "addCategoryName") setAddCategory(value);
    if (name === "addSubCategoryName") setAddSubCategory(value);
    if (name === "addProductCategoryName") setAddProductCategory(value);
    if (name === "productName") setAddProductName(value);
    if (name === "productBrandName") setAddProductBrand(value);
    if (name === "productColourName") setAddProductColour(value);
    if (name === "ProductSizeName") setAddProductSize(value);
    if (name === "productUnitName") setAddProductUnit(value);
    if (name === "DiscountPriceName") setAddDiscountPrice(value);
    if (name === "DescriptionName") setAddDescription(value)
    if (name === "GSTPercentageName") { if (Number.isInteger(Number(value))) { setAddGSTPercentage(value); }}
    if (name === "minOrderName") setAddMinOrder(value)
    if (name === "UnitPrice") {setUnitPrice(value);
        calculateDiscount(value, discountPercentage);
      } else if (name === "DiscountPercentage") {setDiscountPercentage(value);
        calculateDiscount(unitPrice, value);
      }
  };
  const calculateDiscount = (price, discount) => {
    if (price && discount) {
      const discountAmount = (price * discount) / 100;
      setAddDiscountPrice(Math.round(price - discountAmount));
    }
    else{
      setAddDiscountPrice(price)
    }
  };

  const resetValues = () => {
    setAddProductName("");
    setAddProductBrand("")
    setAddProductColour("")
    setAddProductSize("")
    setAddDiscountPrice("")
    setAddDescription("")
    setAddProductUnit("") 
    setAddGSTPercentage("")
    setCheckedState(new Array(addproductData.length).fill(false))   
    setSelectedImages([])
    setMainImg(0)
    setAddMinOrder("") 
    setErrors({});
  }

  const handleSubmit = (e) => {

    e.preventDefault();
    if(!validateForm()) return;

    const bodyData = {
      categoryId: selectedCategory.id,
      subCategoryId: selectedSubCategory.id,
      productCategoryId: selectedProductCategory.id,
      productName: addProductName,
      brandId: addproductBrand,
      colorId: addProductColour,
      productSizeId: addProductSize,
      unitId: addProductUnit,
      discountedPrice: addDiscountPrice,
      description: addDescription,
      rating: 5,
      fastSelling: checkedState[0],
      trending: checkedState[1],
      premium: checkedState[2],
      common: checkedState[3],
      veg: checkedState[4],
      images: selectedImages,
      minOrder: addMinOrder,
      gst: addGSTPercentage,
      mainImage: mainImg,
      unitPrice: unitPrice,
      discountPercentage: ''
    };

    setLoading(true)
    addProduts(bodyData).then(response => {
      if(response.data.code === 200) {
        toast.success(response.data.message, {
          onClose: () => {
            navigate('/products/getProducts')
          }
        })
        setLoading(false)
        resetValues()   
      } else {
        toast.error(response.data.message)
        setLoading(false)
        resetValues()
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    getProductBrandsApi().then(response => {
      const data = response.data.data
      productContext.productBrandList(data)
      setLoading(false)
    });

    getProductColorApi().then(response => {
      const data = response.data.data
      productContext.productColorList(data)
      setLoading(false)
    });

    getProductSizeApi().then(response => {
      const data = response.data.data
      productContext.productSizeList(data)  
      setLoading(false)
    });

    getProductUnitApi().then(response => {
      const data = response.data.data
      productContext.productUnitList(data)
      setLoading(false)
    });

  }, [])


  const subcategoryShow = () => {
    setShowSubCategory(true)
    setShowProductCategory(false)
    setAddProductDetails(false)
  }

  const productCategoryShow = () => {
    setShowProductCategory(true)
    setAddProductDetails(false)    
  }

  const productDetailsShow = () => {
    setAddProductDetails(true)
  } 
  useEffect(() => {
    const handleWheel = () => {
      if (document.activeElement.type === "number" &&
          document.activeElement.classList.contains("noscroll")) {
          document.activeElement.blur();
      }
    };
    document.addEventListener("wheel", handleWheel);
    return () => document.removeEventListener("wheel", handleWheel);

  }, []);

  return (
    <>
    <div className=" overflow-y-auto no-scrollbar h-screen font-inter">
    {loading && <Loader backgroundColor='transparent' />}    
    <div className="px-7 py-10 flex-col flex no-scrollbar overflow-y-scroll relative">
      <label className="font-inter font-semibold text-dark-brown text-base">Add Product</label>
        <div className="md:flex flex-row flex-nowrap block">
          <div className="mt-4 flex-4">
            <input className="px-2 py-1 w-95% mb-2 outline-none" onChange={handleCategoryChange} type="text" placeholder="Search Category" name="addCategoryName" value={addCategory} />
            <div className="overflow-y-auto max-h-80 min-h-auto pr-2">
              {filteredCategoryData && filteredCategoryData.map((item) => (
                  <ListSelection key={item.id} item={item}  onClick={() => handleCategoryClick(item)} selectedCategory={selectedCategory} >
                    <div className="pl-2 pr-2 w-full cursor-pointer" onClick={subcategoryShow}>
                      <label className="cursor-pointer" style={{ color: item === selectedCategory ? "#FFFFFF" : "#555555"}}>
                        {item.categoryName}
                      </label>
                    </div>
                  </ListSelection>
              ))}
              <div className="w-95% bg-dark-brown-70-opacity py-2 cursor-pointer flex items-center justify-center mt-2">
                <button className="cursor-pointer text-white" onClick={handleCategorySubmit}> Add Category </button>
              </div>
            </div>
          </div>

          {showSubCategory &&
            <div className="mt-4 ml-2 flex-4">
              <input className="px-2 py-1 w-95% mb-2 outline-none" type="text" placeholder="Search Sub Category" value={addSubCategory} onChange={handleSubCategoryChange} name="addSubCategoryName" />
              <div className="overflow-y-auto max-h-80 min-h-auto pr-2">
                {filteredSubCategoryData?.length > 0 && (     
                  filteredSubCategoryData.map((item) => (
                    <ListSelection key={item.id} item={item} onClick={() =>onSubCategoryClick(item) } selectedCategory={selectedSubCategory} >
                      <div className="pl-2 pr-2 w-full cursor-pointer" onClick={productCategoryShow}>
                        <label className="cursor-pointer" style={{color: item === selectedSubCategory ? "#FFFFFF" : "#555555"}}>{item.subCategoryName}</label>                  
                      </div>
                    </ListSelection>
                  ))             
                )}                 
                <div className="w-95% bg-dark-brown-70-opacity py-2 cursor-pointer flex items-center justify-center mt-2">
                  <button className="cursor-pointer text-white" onClick={handleSubCategorySubmit}>Add Sub Category </button>
                </div>
              </div>
            </div>
          }          

          {showProductCategory &&
            <div className="mt-4 ml-2 flex-4">
              <input name="addProductCategoryName"  onChange={handleProductCategoryChange} value={addProductCategory}  className="px-2 py-1 w-95% mb-2 outline-none" type="text" placeholder="Search Product Category" />
              <div className="overflow-y-auto max-h-80 min-h-auto pr-2">
               {productContext.productCategoryList?.length > 0 && (
                filteredProductData?.map((item) => (
                  <ListSelection key={item.id} item={item} onClick={onProductCategorySelect}  selectedCategory={selectedProductCategory} >
                    <div className="pl-2 pr-2 w-full cursor-pointer" onClick={() => productDetailsShow()} >
                      <label className="cursor-pointer"  style={{ color: item === selectedProductCategory ? "#FFFFFF" : "#555555"}}>{item.productCategory}</label>
                    </div>
                  </ListSelection>
                )))}
                <div className="w-95% bg-dark-brown-70-opacity py-2 cursor-pointer flex items-center justify-center mt-2">
                  <button  onClick={handleProductCategorySUbmit} className="cursor-pointer text-white" >  Add Product Category</button>
                </div>
              </div>
            </div>
          }

          {addProductDetails && (
          <div className="md:w-full w-auto h-auto p-5 mt-4 block md:h-[80vh] overflow-y-auto no-scrollbar" style={{ boxShadow:  "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px" }}>
            <div className="md:grid grid-cols-12 gap-5">
              <div className="col-span-7">
                <div className="border-border-gray-outline border-2 rounded-lg p-5">
                  <div className="flex items-center space-x-2">
                    <label>MainImage:</label>
                    <div className="cursor-pointer"><input  type="file" className="text-xs cursor-pointer" onChange={handleMainImageSelect} />   </div>             
                  </div>  
                  {errors.mainImg && <p className='text-xs text-red-600'>{errors.mainImg}</p>}
                  <div className="md:flex justify-center mt-6 ">
                    <input type="file" ref={fileInputRef} className="hidden" onChange={handleFileSelect} multiple />
                    <div className="flex flex-col justify-center w-40 h-auto border border-dashed rounded-md border-dark-brown pt-4 pb-4  bg-border-gray-outline">
                      <img  className="w-6 h-auto ml-16" src={uploadIcon} alt="upload icon" />
                      <p className="cursor-pointer mt-3 text-blue-500 text-center " onClick={() => fileInputRef.current.click()}><span className="border-b-2 border-blue-400"> Click here to upload</span></p>
                    </div>
                  </div>               

                  <div className="flex justify-center">
                    <div className="mt-6 mb-1 relative overflow-x-scroll whitespace-nowrap" style={{ scrollbarWidth: "thin" }} >
                      {selectedImages.length > 0 ? selectedImages.map((base64Image, index) => (
                        <div key={index} className="relative m-2 inline-block" >
                          <img className="w-24 h-20 object-cover" src={handleDecrypt(base64Image)} alt={`image-${index}`} />
                          <button className="absolute top-[-6px] right-[-4px] bg-gray-500 text-white rounded-full text-xs w-4" onClick={() => handleRemoveImage(index)} > &times; </button>
                        </div>
                      )) : null}         
                    </div>
                    {errors.selectedImages && <p className='text-xs text-red-600'>{errors.selectedImages}</p>}
                  </div>
                </div>       
                <h5 className="mt-8 mb-4">Description</h5>
                <div className=" border-border-gray-outline border-2 rounded-lg w-full p-5">
                  <div>
                    <label className="text-gray-500">Product Name</label>
                    <input className="border-2 w-full outline-none p-2 mt-2 mb-3 required:border-red-500"  name="productName" onChange={handleChange} value={addProductName} type="text" placeholder="Enter product name" />
                    {errors.addProductName && <p className='text-xs text-red-600'>{errors.addProductName}</p>}
                  </div>
                  <div className="md:grid grid-cols-2 gap-5">
                    <div className="col-span-1 w-full">
                      <label className="text-gray-500">Brand</label>
                      <select className="w-full border-2 outline-none p-2 mt-2 mb-3" name="productBrandName" type="text"  onChange={handleChange} value={addproductBrand} >                   
                        <option defaultChecked>Select Brand</option>
                        {productContext.productBrands && productContext.productBrands.length > 0 ? ( productContext.productBrands.map((brand) => (
                          <option key={brand.id} value={brand.id}>{brand.brandName}</option>
                        ))): null}                      
                      </select>
                      {errors.addproductBrand && <p className='text-xs text-red-600'>{errors.addproductBrand}</p>}
                    </div>
                    <div className="col-span-1 w-full">
                      <div className="flex justify-between pr-2">
                        <label className="text-gray-500">Colour</label>
                        <Link  to="/products/AddProductColor"  rel="noopener noreferrer" className="text-blue-500 text-lg font-bold" > + </Link>                  
                      </div>
                      <select className="w-full border-2 outline-none p-2 mt-2 mb-3" type="text" name="productColourName" value={addProductColour} onChange={handleChange}>
                        <option>Select Color</option>
                        {productContext.productColor && productContext.productColor.length > 0 ? (productContext.productColor.map((item) => (
                          <option key={item.id} value={item.id}>{" "} {item.colorName}</option>                          
                        ))): null}                
                        <option value="add-color">Add color</option>
                      </select>
                      {errors.addProductColour && <p className='text-xs text-red-600'>{errors.addProductColour}</p>}
                    </div>
                  </div>
                  <div className="md:grid grid-cols-2 gap-5">
                    <div className=" col-span-1 w-full">
                      <div className="flex justify-between pr-2">
                        <label className="text-gray-500">Product Size</label>
                        <Link  to="/products/AddProductSize" className="text-blue-500 text-lg font-bold" > + </Link>
                      </div>
                      <select className="w-full border-2 outline-none p-2 mt-2 mb-3" name="ProductSizeName" type="text" value={addProductSize} onChange={handleChange}>
                        <option>Select Size</option>
                        {productContext.productSize && productContext.productSize.length > 0 ? ( productContext.productSize.map((item) => (
                          <option key={item.id} value={item.id}>{item.name}</option>
                        ))) : null}
                      </select>
                      {errors.addProductSize && <p className='text-xs text-red-600'>{errors.addProductSize}</p>}
                    </div>
                    <div className="col-span-1 w-full">
                      <div className="flex justify-between pr-2">
                        <label className="text-gray-500">Unit</label>
                        <Link  to="/products/AddProductUnit" className="text-blue-500 text-lg font-bold" > + </Link>                  
                      </div>
                      <select className="w-full border-2 outline-none p-2 mt-2 mb-3"  type="text" name="productUnitName" value={addProductUnit} onChange={handleChange}>
                        <option>Select Unit</option>
                        {productContext.productUnit && productContext.productUnit.length > 0 ? (productContext.productUnit.map((unit) => (
                          <option key={unit.id} value={unit.id}>{unit.unitName}</option>
                        ))): null}                   
                      </select>
                      {errors.addProductUnit && <p className='text-xs text-red-600'>{errors.addProductUnit}</p>}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-5">
                    <div className="col-span-1">
                     <label className="text-gray-500">Price (&#8377;)</label>
                      <input className="border-2 w-full outline-none p-2 mt-2 mb-3 noscroll" name="UnitPrice" type="number" onChange={handleChange}/>
                    </div>
                    <div className="col-span-1">
                     <label className="text-gray-500">Discount (%)</label>
                      <input className="border-2 w-full outline-none p-2 mt-2 mb-3 noscroll" name="DiscountPercentage" type="number"  onChange={handleChange} />
                    </div>
                  </div>
                  <div>
                    <label className="text-gray-500">Discount Price (&#8377;)</label>
                    <input readOnly className="border-2 bg-gray-200 w-full outline-none p-2 mt-2 mb-3 noscroll" name="DiscountPriceName" type="number" value={addDiscountPrice} onChange={handleChange} ></input>
                    {errors.addDiscountPrice && <p className='text-xs text-red-600'>{errors.addDiscountPrice}</p>}
                  </div>                
                </div>
              </div>
              <div className="col-span-5">
                <div className="  items-center border-border-gray-outline border-2 rounded-lg p-5">
                  {addproductData.map((item, index) => (
                    <div key={item.id} className="w-full md:flex gap-2 items-center relative mb-3" >
                      <div className="relative">
                        <img className="w-5 h-5 cursor-pointer" src={CheckBox}  alt="" onClick={() => handleToggle(index)} />
                        <img className={`absolute top-1 left-1 w-3 h-3 ${ checkedState[index] ? "block" : "hidden" }`} src={CheckTick} onClick={() => handleToggle(index)} alt="" />
                      </div>
                      <p>{item.productData}</p>
                    </div>
                  ))}
                </div>
                <h5 className="mt-8 mb-4">Pricing</h5>
                <div className="border-border-gray-outline border-2 rounded-lg p-5">
                  <div className="w-full">
                    <label className="text-gray-500">GST</label>
                    <div className="flex ">
                      <div className="bg-gray-200  p-2 mt-2 mb-3 w-10 text-gray-500"> % </div>
                      <input className="w-full border-s outline-none p-2 mt-2 mb-3 noscroll" type="number" name="GSTPercentageName" value={addGSTPercentage} onChange={handleChange} />
                    </div>
                    {errors.addGSTPercentage && <p className='text-xs text-red-600'>{errors.addGSTPercentage}</p>}
                  </div>
                  <div className="w-full">
                    <label className="text-gray-500">Minimum Order</label>
                    <div className="flex">                    
                      <input className="w-full border-s outline-none p-2 mt-2 mb-3 noscroll"  type="number"  name="minOrderName" value={addMinOrder} onChange={handleChange} />                    
                    </div>
                    {errors.addMinOrder && <p className='text-xs text-red-600'>{errors.addMinOrder}</p>}
                  </div>
                </div>            
              </div>           
            </div>
            <div className='w-full h-48 border overflow-auto'>
              <label className="text-gray-500">Product Description</label>
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                  options: ['inline', 'list', 'textAlign'],
                  inline: { options: ['bold', 'italic', 'underline', 'superscript', 'subscript'] },
                  list: { inDropdown: false },
                  textAlign: { inDropdown: false },
                }}
              />
              <textarea
                name="DescriptionName"
                hidden
                disabled
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
              />
            </div>
            <div className="flex gap-4  mt-8 ml-10 justify-end">
              <div className="border-2 px-3 py-1 hover:bg-dark-brown hover:text-white rounded-sm cursor-pointer" onClick={() => {setAddProductDetails(false)}}>Cancel</div>
              <div className="border-2 px-3 py-1 bg-dark-brown text-white border-dark-brown rounded-sm cursor-pointer" onClick={handleSubmit}>Save</div>
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
};
export default AddProducts;
