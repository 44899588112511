import React from 'react';

const Loader = ({ color, backgroundColor }) => {

    return (
        <>   
          <div style={{ position:'absolute', top:'0', left:'0', width:'100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '999'}}>
            <div style={{ backgroundColor: backgroundColor || '#80808030', padding: '25px', borderRadius:'5px' }}>
              <div style={{ 
                border: `5px solid ${color || '#703F07'}`, 
                borderRadius: '50%', 
                borderTop: '5px solid transparent', 
                width: '50px', 
                height: '50px', 
                animation: 'spin 1s linear infinite' 
              }}></div>
            </div>
          </div>
          <style>
            {`
              @-webkit-keyframes spin {
                0% { -webkit-transform: rotate(0deg); }
                100% { -webkit-transform: rotate(360deg); }
              }
    
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
          </style>
        </>
      );

}
export default Loader;