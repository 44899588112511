import { upload } from "@testing-library/user-event/dist/upload";
import { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";

const Dropzone = ({ className, Onfilechange, initialImageUrl }) => {
  const [files, setFiles] = useState(null);
  const [fileRemoved, setFileRemoved] = useState(false);

  console.log(files, "upload");

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles?.length) {
        const newFile = acceptedFiles[0];
        setFiles(
          Object.assign(newFile, { preview: URL.createObjectURL(newFile) })
        );
        Onfilechange(newFile);
        setFileRemoved(false);
      }
    },
    [Onfilechange]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const removeFile = () => {
    setFiles(null);
    setFileRemoved(true);
    Onfilechange(null);
  };

  const fileSize = (size) => {
    return (size / 1024).toFixed(2);
  };

  useEffect(() => {
    if (initialImageUrl) {
      setFiles({ preview: initialImageUrl });
    }
  }, [initialImageUrl]);

  return (
    <div className="flex items-start gap-4">
      <div {...getRootProps({ className: className })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="font-inter" style={{ fontSize: "9px" }}>
            Drop the files here ...
          </p>
        ) : (
          <div className="flex flex-col items-center gap-3">
            <p className="font-inter text-center" style={{ fontSize: "9px" }}>
              Drop the files here or click to upload
            </p>
          </div>
        )}
      </div>
      {files ? (
        <div className="border border-neutral-200 py-4 px-2 flex items-center justify-between gap-6">
          <div className="flex items-center gap-2">
            <img
              src={files.preview}
              alt="Brand"
              className="object-contain w-28 "
              height={50}
            />
            <div className="flex flex-col gap-1">
              <span className="text-xs font-semibold font-inter">
                {files.name}
              </span>
            </div>
          </div>
          <button
            className="p-1 bg-red-600 text-white font-inter rounded "
            style={{ fontSize: "9px" }}
            onClick={removeFile}
          >
            Delete
          </button>
        </div>
      ) : fileRemoved ? (
        <p className="pt-3 text-red-600 text-xs font-inter"></p>
      ) : null}
    </div>
  );
};

export default Dropzone;
