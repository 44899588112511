import React, { useContext, useEffect, useState } from 'react'
import InvoiceCard from './InvoiceCard'
import { Eye, InvoiceLogo1, InvoiceLogo2, InvoiceLogo3, InvoiceLogo4 } from '../../assets/icons'
import { Link } from 'react-router-dom'
import Pagination from "../../acsComponents/Pagination"
import SearchBox from "../../acsComponents/SearchBar"
import { InvoiceStates } from '../../Context/InvoiceContext'
import { invoiceData } from '../../Networking/InvoiceAction'
import { setUpCookie } from '../../utils/Cookie'
import { TOKEN } from '../../utils/Constant'
import Loader from '../../acsComponents/Loader'

const Invoice = () => {

    const [data, setData] = useState([])
    const [searchInput, setSearchInput] = useState("")
    const [filteredData, setFilteredData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const invoiceContext = useContext(InvoiceStates)    

    useEffect(() => {
        fetch('https://jsonplaceholder.typicode.com/use').then(res => res.json()).then(res => setData(res))
        setIsLoading(true)
        invoiceData().then(res => {
            if (res.data.accessToken) setUpCookie(TOKEN, res.data.accessToken)
            invoiceContext.updateInvoiceData(res.data.data)
            setIsLoading(false)
        }).catch(() => setIsLoading(false))
    }, [])

    const handleSearch = (value) => setSearchInput(value.toLowerCase())

    useEffect(() => {
        if(searchInput) {
            const filter = data.filter(item => item.name.toLowerCase().includes(searchInput.toLowerCase()))
            setFilteredData(filter)
        } else {
            setFilteredData(data)
        }
    }, [searchInput, data])

  return (
    <div className='px-9 py-8 h-screen overflow-auto no-scrollbar'>
        {isLoading && <Loader backgroundColor='transparent' />}
        <div className='flex justify-between'>
            <p className='font-inter font-bold text-base text-dark-brown'>INVOICE LIST</p>
            <p className='font-inter text-xs me-10'>Invoices &gt; <span className='text-dark-brown'>List view</span></p>
        </div>
        <div className='mt-5 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 md:gap-16'>
            <InvoiceCard backgroundColor="#FCF2DA" image={InvoiceLogo1} item={{ name: "Clients", totalcount: invoiceContext.invoiceData?.totalUsers ? invoiceContext.invoiceData?.totalUsers : 0 }} />
            <InvoiceCard backgroundColor="#CDF7EC" image={InvoiceLogo2} item={{ name: "Invoices", totalcount: invoiceContext.invoiceData?.totalOrders ? invoiceContext.invoiceData?.totalOrders : 0 }} />
            <InvoiceCard backgroundColor="#D2EDF6" image={InvoiceLogo3} item={{ name: "Paid", totalcount: invoiceContext.invoiceData?.totalDeliveredAmount ? invoiceContext.invoiceData?.totalDeliveredAmount : 0 }} />
            <InvoiceCard backgroundColor="#D2EDF6" image={InvoiceLogo4} item={{ name: "Unpaid", totalcount: invoiceContext.invoiceData?.totalUndeliveredAmount ? invoiceContext.invoiceData?.totalUndeliveredAmount : 0 }} />
        </div>

        <div className='mt-5 w-full max-h-screen bg-white rounded shadow-xl'>
            <div className='grid md:flex justify-between items-center'>
                <Link to="/createInvoice"><button className='ms-3 md:ms-6 mt-4 px-3 py-2 bg-dark-brown text-white font-inter font-semibold rounded' style={{ fontSize: '10px' }}>Create Invoice</button></Link>
                <div className='ms-3 md:ms-0 md:me-5 mt-4'>
                    <SearchBox placeholder='Search Invoice' onInputChange={handleSearch} />
                </div>
            </div>
            <div className='mt-3 no-scrollbar max-h-96 overflow-y-auto pb-3'>
                <div className='flex flex-col space-y-2'>
                    <div className='hidden md:flex bg-light-brown p-3 font-inter font-semibold text-xs text-center sticky top-0'>
                        <div className='flex-1'>Client</div>
                        <div className='flex-1'>Total</div>
                        <div className='flex-1'>Issued Date</div>
                        <div className='flex-1'>Balance</div>
                        <div className='flex-1'>Action</div>               
                    </div>
                    <Pagination data={filteredData} justifyContent='end'>
                    {filteredData?.length > 0 ? filteredData.map((item, index) => (
                        <div key={index} className='flex flex-col md:flex-row border-b border-gray-300 py-3'>
                            <div className='flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center'><span className='md:hidden text-nowrap font-bold'>Client: </span>{item.name}</div>
                            <div className='flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center'><span className='md:hidden text-nowrap font-bold'>Total: </span>{item.id}</div>
                            <div className='flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center'><span className='md:hidden text-nowrap font-bold'>Issued Date: </span>{item.id}</div>
                            <div className='flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center'><span className='md:hidden text-nowrap font-bold'>Balance: </span>{item.id}</div>          
                            <div className='flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center cursor-pointer'><span className='md:hidden text-nowrap font-bold'>Action: </span><img src={Eye} alt='eye-icon' width={15} height={15} /></div>                
                        </div>
                    )) : (
                        <div className='flex-1 text-center py-3 italic font-inter font-medium text-sm text-red-500'>No data available.</div>
                    )}
                    </Pagination>
                </div>
            </div>
        </div>        
    </div>
  )
}

export default Invoice