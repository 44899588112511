import { Link, useLocation } from "react-router-dom"
import { CloseButton } from "../../assets/icons"
import { useEffect, useState } from "react"
import Loader from "../../acsComponents/Loader"
import { getAllCoupons } from "../../Networking/CouponAction"
import { setUpCookie } from "../../utils/Cookie"
import { TOKEN } from "../../utils/Constant"

const ViewCoupon = () => {

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation()
    const id = location.state?.id

    useEffect(() => {
        setIsLoading(true)
        getAllCoupons().then(res => {
            if (res.data.accessToken) setUpCookie(TOKEN, res.data.accessToken)
            setData(res.data.data)
            setIsLoading(false)
        }).catch(() => setIsLoading(false))
    }, [id])

    const couponData = data.find(item => item.id === id)
    
  return (
    <div className='px-4 py-14 sm:px-6 md:px-8 lg:px-10'>
        {isLoading && <Loader />}
        <div className='mt-3 w-full bg-white rounded shadow-lg'>
            <div className='pt-5 px-5 flex justify-end'>
                <Link to='/coupons'><img src={CloseButton} alt="Back Button" width={15} height={15} className='ms-6'/></Link>
            </div>
            {couponData && couponData !== null ? (
            <div className='px-28 py-3 pb-16'>
                <div className='grid grid-cols-1 gap-6 lg:grid-cols-3 items-center'>
                    <div>
                        <img src={!couponData.couponImage ? "no-image" : couponData.couponImage} alt={couponData.offerName} className='rounded-md h-40 w-48 object-cover' />
                    </div>
                    <div className='space-y-5'>
                        <div className='flex'>
                            <p className='flex-1 font-inter font-bold text-xs'>Coupon Title</p>
                            <p className='flex-1 font-inter text-sm'>{couponData.offerName}</p>
                        </div>
                        <div className='flex'>
                            <p className='flex-1 font-inter font-bold text-xs'>Coupon Code</p>
                            <p className='flex-1 font-inter text-sm'>{couponData.couponCode}</p>
                        </div>
                        <div className='flex'>
                            <p className='flex-1 font-inter font-bold text-xs'>Discount</p>
                            <p className='flex-1 font-inter text-sm'>{couponData.discount} %</p>
                        </div>
                    </div>
                    <div className='space-y-5'>
                        <div className='flex'>
                            <p className='flex-1 font-inter font-bold text-xs'>Start Date</p>
                            <p className='flex-1 font-inter text-sm'>{couponData.startDate}</p>
                        </div>
                        <div className='flex'>
                            <p className='flex-1 font-inter font-bold text-xs'>End Date</p>
                            <p className='flex-1 font-inter text-sm'>{couponData.endDate}</p>
                        </div>
                        <div className='flex'>
                            <p className='flex-1 font-inter font-bold text-xs'>Status</p>
                            <p className='flex-1 font-inter text-sm'>{couponData.active ? 'Active' : 'InActive'}</p>
                        </div>
                    </div>

                </div>
            </div>
            ) : (
                <p className="flex-1 text-center py-3 italic font-inter font-medium text-sm text-red-500">No Content</p>
            )}
        </div>
    </div>
  )
}

export default ViewCoupon   