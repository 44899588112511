import React, { useEffect, useContext } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getGraphData } from '../../Networking/DashboardApiActions';
import {DashboardStates} from '../../Context/DashboardContext'

const Example = () => {
  const dashboardContext = useContext(DashboardStates)

  useEffect(() => {
    getGraphData().then(response => {
      const apiData = response.data.data;
      const formattedData = apiData.map(item => ({
        week: item.week,
        previousMonth: item.currentMonthProfit, 
        currentMonth: item.lastMonthProfit,
      }));
      dashboardContext.updateChartData(formattedData);
    })
  }, []);

  return (
    <div className='w-auto h-80 mt-10'>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart width={500} height={300} data={dashboardContext.chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5,}}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="week" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="previousMonth" strokeWidth={2} stroke="#e3bb8e" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="currentMonth" strokeWidth={2} stroke="#703F07B3" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Example;
