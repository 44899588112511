import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { addProductUnit } from "../../Networking/ProductApisActions";
import Loader from "../../acsComponents/Loader";
import { toast } from "react-toastify";
import { setUpCookie } from "../../utils/Cookie";
import { TOKEN } from "../../utils/Constant";

const AddProductUnit = () => {
  const navigate = useNavigate()
  const [unit, setUnit] = useState("")
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const validateForm = () => {
    let formErrors = {};
    if (!unit) {
        formErrors.unit = "Unit is required.";
    }   
    setErrors(formErrors);
      return Object.keys(formErrors).length === 0;
  }

  const handleUnitSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    if(!validateForm()) return
    const productData = {
      name: unit,
    };

    addProductUnit(productData).then(response => {
      if(response.data.code === 200) {
        if (response.data.accessToken) setUpCookie(TOKEN, response.data.accessToken)
        toast.success(response.data.message)
        setLoading(false)
        setUnit("")
      } else {
        toast.error(response.data.message)
        setLoading(false)
      }
    })
  };

  return (
    <div className=" overflow-y-auto no-scrollbar h-screen font-inter">
     {loading && <Loader backgroundColor='transparent' />}
      <div className="flex justify-center items-center w-full h-screen">
        <div className="w-96 h-auto">
          <div className="flex justify-between w-full pl-2 pr-2 pb-2">
            <p className="text-dark-brown">Add Product Unit</p>          
          </div>
          <div className="w-full h-auto bg-white p-6">
            <form onSubmit={handleUnitSubmit}>            
              <label>Product Unit</label>
              <input
                type="text"
                className="w-full p-2 border outline-none"
                name="unit"
                onChange={(e) => {setUnit(e.target.value)}}
                value={unit}                
              />
               {errors.unit && <p className='text-xs text-red-600'>{errors.unit}</p>}
              <div>                        
              </div>
              <button
                type="submit"
                className="bg-darkbrown border px-3 py-1 mt-8 bg-dark-brown text-white"
              >
                Save
              </button>
              <button
                type="button"
                className="border-2 ml-2 px-3 py-1 hover:bg-dark-brown hover:text-white rounded-sm cursor-pointer"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
      </div>
  );
};

export default AddProductUnit;
