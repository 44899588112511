import React, { useContext, useEffect, useState } from 'react';
import Dropzone from './Dropzone';
import { Link, useNavigate } from 'react-router-dom';
import { ProductStates } from '../../Context/ProductContext';
import { getAllBrands, updateBrands } from '../../Networking/ProductApisActions';
import Loader from '../../acsComponents/Loader';
import { setUpCookie } from '../../utils/Cookie';
import { TOKEN } from '../../utils/Constant';
import { toast } from 'react-toastify';

const AddBrand = () => {
    const [selectedData, setSelectedData] = useState([]);
    const [brandName, setBrandName] = useState("");
    const [errors, setErrors] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [imageUrl, setImageUrl] = useState("");
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const productContext = useContext(ProductStates);

    const selectedBrandId = productContext.editedBrandId;

    const validateForm = () => {
        let formErrors = {};
        if (!brandName) {
            formErrors.brandName = "brand Name is required.";
        } 
        if (!imageUrl) {
            formErrors.imageUrl = "Image is required.";
        }
        setErrors(formErrors);

        return Object.keys(formErrors).length === 0;
      }   

      const handleFileChange = (file) => {
        setFileData(file);
        if (file) {
            setImageUrl(URL.createObjectURL(file));
        } else {
            setImageUrl("");
        }
    };
    

    const handleSubmit = () => {
        if (!validateForm()) return; 

        const formData = new FormData();
        formData.append('brandId', selectedBrandId);
        formData.append('brandName', brandName);
        formData.append('isActive', 1);
        if (fileData) {
            formData.append('files', fileData);
        }
        setLoading(true);

        updateBrands(formData)
            .then(response => {
                if(response.data.code === 200) {
                    if (response.data.accessToken) setUpCookie(TOKEN, response.data.accessToken)
                    toast.success(response.data.message, {
                        onClose: () => navigate('/brands')
                    })               
                    setLoading(false);
                } else {
                    toast.error(response.data.message)
                    setLoading(false)
                }    
            })
            .catch(() => {
                setLoading(false);
            });          
    };  

    useEffect(() => {
        getAllBrands()
            .then(response => {
                setSelectedData(response.data.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const selectedBrandData = selectedData.find(item => item.id === selectedBrandId);

    useEffect(() => {
        if (selectedBrandData) {
            setBrandName(selectedBrandData.brandName || '');
            setImageUrl(selectedBrandData.imageUrl || '')
        }
    }, [selectedBrandData]);

    return (
        <div className='px-10 py-16 h-screen overflow-auto no-scrollbar'>
        {loading && <Loader backgroundColor='transparent' />}
            <div className='bg-white shadow-lg rounded'>
                <div className='px-20 py-9'>
                    <h3 className='text-xs font-inter font-semibold'>Create Brand Profile</h3>
                    {selectedBrandData ? (
                        <div key={selectedBrandData.id}>
                            <div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
                                <div className='mt-4'>
                                    <label className='block mb-2 text-xs font-inter font-semibold'>Brand Name</label>
                                    <input
                                        type='text'
                                        className={`w-full appearance-none ${errors && !brandName ? 'border border-red-600' : 'border'} rounded-md py-2 px-3 text-gray-700 text-xs font-inter focus:outline-none`}
                                        placeholder='Enter Brand Name'
                                        value={brandName}
                                        onChange={(e) => setBrandName(e.target.value)}
                                    />
                                    {errors.brandName && <p className='text-xs text-red-600'>{errors.brandName}</p>}
                                </div>
                            </div>
                            <div className='grid grid-cols-1 mt-8'>
                                <label className='block mb-2 text-xs font-inter font-semibold'>Brand Image</label>
                                <Dropzone className={`px-5 py-3 border ${errors.imageUrl ? 'border-red-600' : 'border-neutral-200'} cursor-pointer`} Onfilechange={handleFileChange} initialImageUrl={imageUrl}/>
                                {errors.imageUrl && <p className='text-xs text-red-600'>{errors.imageUrl}</p>}
                            </div>
                            <div className='flex justify-end gap-4 mt-2 pb-8'>
                                <Link to='/brands'>
                                    <button className='text-gray-500 text-xs font-inter font-semibold'>Cancel</button>
                                </Link>
                                <button className='bg-dark-brown px-4 py-1.5 text-xs font-inter text-white rounded-sm' onClick={handleSubmit}>Update Brand</button>
                            </div>
                        </div>
                    ) : (
                        <p>No Data Found</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddBrand;
