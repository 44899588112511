import customericon from "../../assets/svg/customer.svg";
import shippingIcon from "../../assets/svg/shippingIcon.svg";
import billingIcon from "../../assets/svg/billingIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { IoPencilSharp } from "react-icons/io5";
import { useEffect, useState } from "react";
import { getAllOrders } from "../../Networking/OrderAction";
import { setUpCookie } from "../../utils/Cookie";
import Loader from "../../acsComponents/Loader";

const tableHeads = [
  "Product",
  "Category",
  "Brand",
  "Color",
  "Quantity",
  "Price",
  "Status",
];

function OrderOverview() {

  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { order, orderId } = location.state;
  
  useEffect(() => {
    setLoading(true);
    getAllOrders().then(res => {
      if (res.data.accessToken) setUpCookie(res.data.accessToken);
      setOrderData(res.data.data.orderResponseList);
      setLoading(false);
    })
  }, [])

  const filterOrderData = orderData.find(item => item.id === orderId);

  const handleUpdateStatus = (itemId, itemName, status) => {
    navigate(`/update-itemStatus/${itemId}`, { state: { itemId, itemName, status } });
  };

  return (
    <div className="no-scrollbar pl-10 pr-10 pt-5 pb-8 h-screen overflow-auto">
      {loading && <Loader backgroundColor='transparent' />}
      <div className="flex justify-between mb-4">
        <p className="font-inter text-dark-brown text-base font-semibold">
          ORDERS VIEW LIST
        </p>
        <p className=" text-right text-dark-brown text-sm font-inter font-medium">
          <span className="text-gray"> orders &gt; </span> Listview
        </p>
      </div>

      {order && order !== null && filterOrderData && filterOrderData !== null ? (
        <>
          <div className="mt-6 grid grid-cols-6 grid-flow-row">
            <div className="md:col-span-3 col-span-6 flex items-center">
              <p className=" text-dark-brown text-sm font-inter font-medium">
                <span className="text-gray"> Order ID: </span>{" "}
                <span className="font-bold text-base">{order.orderId}</span>
              </p>
            </div>
            <div className="md:col-span-3 col-span-6 text-end">
              <p className="text-dark-brown text-sm font-inter font-medium">Ordered Date: <span>{filterOrderData.createdAt}</span></p>
            </div>
            {/* <div className="md:col-span-3 col-span-3 border-2 border-gray-border rounded-lg md:h-10 h-8 "></div>
            <div className="col-span-1 flex justify-center items-center">
              <div className="text-white bg-dark-brown w-max cursor-pointer flex items-center font-semibold text-sm md:text-base pt-1 pb-1 ps-3 pe-3 rounded-sm md:ml-0 ml-16">
                <span className="md:mr-3 mr-0">
                  <img src={download} alt="download icon" />
                </span>
                Download
              </div>
            </div> */}
          </div>

          <div className="grid grid-col-1 md:grid-cols-3 grid-flow-row  md:gap-2 lg:gap-6  mt-6">
            <div
              className="rounded-lg  bg-light-green h-32 mt-1"
              style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
            >
              <div className="flex justify-between pt-3 pb-3 ps-10 md:ps-5 pe-10">
                <div>
                  <p className="text-base font-medium font-inter leading-7 ">
                    {" "}
                    Customer info{" "}
                  </p>
                  <p className="mt-1 text-sm font-semibold font-inter leading-5">
                    {filterOrderData.userName}
                  </p>
                  <p className="text-xs font-normal font-inter leading-5 ">
                    {filterOrderData.address}
                  </p>
                  <p className="text-xs font-normal font-inter leading-5"></p>
                </div>
                <div className="flex justify-center items-center">
                  <img
                    className="pt-3 pb-3 ps-4 pe-4 bg-card-bg-green  rounded-md w-12 max-w-12"
                    src={customericon}
                    alt="customer icon"
                  />
                </div>
              </div>
            </div>

            <div
              className="rounded-lg bg-lg-blue h-32 mt-1"
              style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
            >
              <div className="flex justify-between pt-3 pb-3 ps-10 md:ps-5 pe-10">
                <div>
                  <p className="text-base font-medium font-inter leading-7 ">
                    Shipping info
                  </p>
                  <p className="mt-1 text-sm font-semibold font-inter leading-5">
                    {filterOrderData.userName}
                  </p>
                  <p className="text-xs font-normal font-inter leading-5 ">
                    {filterOrderData.address}
                  </p>
                  <p className="text-sm font-normal font-inter leading-5"></p>
                </div>
                <div className="flex justify-center items-center">
                  <img
                    className="pt-3 pb-3 ps-4 pe-4 bg-card-blue rounded-md w-12 max-w-12"
                    src={shippingIcon}
                    alt="customer icon"
                  />
                </div>
              </div>
            </div>

            <div
              className="rounded-lg bg-card-light-blue  h-32 mt-1"
              style={{ boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px" }}
            >
              <div className="rounded-lg ">
                <div className="flex justify-between pt-3 pb-3 ps-10 md:ps-5 pe-10">
                  <div>
                    <p className="text-base font-medium font-inter leading-7 ">
                      Billing info{" "}
                    </p>
                    <p className="mt-1 text-sm font-semibold font-inter leading-5">
                      {filterOrderData.userName}
                    </p>
                    <p className="text-xs font-normal font-inter leading-5 ">
                      {filterOrderData.address}
                    </p>
                    <p className="text-sm font-normal font-inter leading-5"></p>
                  </div>
                  <div className="flex justify-center items-center">
                    <img
                      className="pt-3 pb-3 ps-4 pe-4 bg-lg-green rounded-md bg-card-bg-light-blue w-12 max-w-12"
                      src={billingIcon}
                      alt="customer icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-4 no-scrollbar overflow-y-auto bg-white shadow-lg rounded-b-lg h-96">
            <div className="sticky top-0">
              <div className="flex justify-center items-center">
                {tableHeads.map((heading) => (
                  <div
                    key={heading}
                    className="flex-1 flex items-center justify-center bg-light-brown font-inter text-xs text-center font-medium h-12"
                  >
                    {heading}
                  </div>
                ))}
              </div>
            </div>
            <div>
              {order.listOrderItems && order.listOrderItems.length > 0 ? (
                order.listOrderItems.map((item) => (
                  <div
                    key={item.id}
                    className="border-b border-b-gray-300 h-12 flex border-border-gray"
                  >
                    <div className="flex-1 flex justify-center items-center  font-inter text-xs text-gray-500">
                      {item.productName}
                    </div>
                    <div className="flex-1 flex justify-center items-center  font-inter text-xs text-gray-500">
                      {item.categoryName}
                    </div>
                    <div className="flex-1 flex justify-center items-center  font-inter text-xs text-gray-500">
                      {item.brandName ? item.brandName : "N/A"}
                    </div>
                    <div className="flex-1 flex justify-center items-center  font-inter text-xs text-gray-500">
                      {item.colorName ? item.colorName : "N/A"}
                    </div>
                    <div className="flex-1 flex justify-center items-center  font-inter text-xs text-gray-500">
                      {item.quantity}
                    </div>
                    <div className="flex-1 flex justify-center items-center  font-inter text-xs text-gray-500">
                      {item.price}
                    </div>
                    <div className="flex flex-1 justify-evenly text-center py-3 font-inter text-xs text-gray-500">
                      {item.orderStatusName} <div className="cursor-pointer" onClick={() => handleUpdateStatus(item.id, item.productName, item.orderStatusName)}><IoPencilSharp /></div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="flex-1 text-center py-3 italic font-inter font-medium text-sm text-red-500">
                  No contents
                </p>
              )}
            </div>
          </div>

          <div className="flex justify-between md:flex-nowrap flex-wrap">
            <div className=" w-96 border border-border-gray bg-white mt-2 ">
              <div className=" p-3 h-19 border border-border-gray font-inter font-medium text-sm">
                Payment Details:
              </div>
              <div className="pl-3 pb-10 font-inter text-xs ">
                <div className="grid grid-cols-2 grid-flow-row leading-7">
                  <div className="col-span-1">Transactions:</div>
                  <div className="col-span-1">-</div>
                </div>
                <div className="grid grid-cols-2 grid-flow-row leading-7">
                  <div className="col-span-1">Payment Method:</div>
                  <div className="col-span-1">{filterOrderData.paymentType}</div>
                </div>
                <div className="grid grid-cols-2 grid-flow-row leading-7">
                  <div className="col-span-1">Card Number:</div>
                  <div className="col-span-1">-</div>
                </div>
                <div className="grid grid-cols-2 grid-flow-row leading-7">
                  <div className="col-span-1">Card Holder Name:</div>
                  <div className="col-span-1">-</div>
                </div>
                <div className="grid grid-cols-2 grid-flow-row leading-7">
                  <div className="col-span-1">Total Amount:</div>
                  <div className="col-span-1 font-bold">
                    &#8377; {order.totalAmount}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 w-80">
              <div className="pl-3 pt-3 font-inter text-xs  border border-border-gray  bg-white ">
                <div className="grid grid-cols-3 grid-flow-row leading-7">
                  <div className="col-span-2">Sub Total:</div>
                  <div className="col-span-1">
                    &#8377; {filterOrderData.finalAmount}
                  </div>
                </div>
                <div className="grid grid-cols-3 grid-flow-row leading-7">
                  <div className="col-span-2">Estimated Tax (12.5%):</div>
                  <div className="col-span-1">&#8377; {filterOrderData.gst}</div>
                </div>
                {/* <div className="grid grid-cols-3 grid-flow-row leading-7">
                  <div className="col-span-2">Shopping Charge:</div>
                  <div className="col-span-1">
                    &#8377; {item.shoppingCharge}
                  </div>
                </div> */}
                <div className="grid grid-cols-3 grid-flow-row leading-7">
                  <div className="col-span-2">Discount:</div>
                  <div className="col-span-1">
                    &#8377; {order.discount}
                  </div>
                </div>
                <div className="grid grid-cols-3 grid-flow-row leading-7 mt-5 font-bold pb-2">
                  <div className="col-span-2 text-center">Total:</div>
                  <div className="col-span-1">
                    &#8377; {order.totalAmount}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full border border-border-gray bg-white mt-2 h-40">
            <div className=" p-3 h-19 border border-border-gray font-inter font-medium text-sm">
              Order Status
            </div>
            <p className="mt-5 flex justify-center items-center uppercase text-xl font-inter font-semibold">
              {filterOrderData.orderStatus}
            </p>
          </div>
        </>
      ) : (
        <div className="flex-1 text-center py-3 font-inter font-medium text-base text-red-500">
          No Data Available. Kindly go back.
        </div>
      )}
    </div>
  );
}
export default OrderOverview;
