import { createContext, useState } from "react";

const DashboardStates = createContext();

const DashboardContext = (props) => {
  const [chartData, setChartData] = useState([]);
  const [dashboardList, setDashboardList] = useState([]);

  const updateDashboard = (update) => {
    setDashboardList(update);
  };

  const updateChartData = (data) => {
    setChartData(data);
  };

  return (
    <DashboardStates.Provider value={{ chartData, dashboardList, updateChartData, updateDashboard }}>
      {props.children}
    </DashboardStates.Provider>
  );
};

export { DashboardContext, DashboardStates };
