import { useContext, useEffect, useState } from "react"
import { IoTrashSharp, IoPencilSharp } from "react-icons/io5"
import { Link, useNavigate } from "react-router-dom"
import SearchBar from "../../acsComponents/SearchBar"
import Pagination from "../../acsComponents/Pagination"
import { CouponStates } from "../../Context/CouponContext"
import { deleteCoupon, getAllCoupons } from "../../Networking/CouponAction"
import Loader from "../../acsComponents/Loader"
import { setUpCookie } from "../../utils/Cookie"
import { TOKEN } from "../../utils/Constant"
import { toast } from "react-toastify"
import { DatePicker } from "antd"

const { RangePicker } = DatePicker

const Coupons = () => {
  const [searchInput, setSearchInput] = useState("")
  const [filteredData, setFilteredData] = useState([])
  const [loading, setLoading] = useState(true)
  const [modal, setModal] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedCouponId, setSelectedCouponId] = useState(null)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  
  const navigate = useNavigate()
  const couponContext = useContext(CouponStates)

  const handleDates = (dates) => {
    if (dates) {
      setStartDate(dates[0].format('DD-MM-YYYY'))
      setEndDate(dates[1].format('DD-MM-YYYY'))
    } else {
      setStartDate('')
      setEndDate('')
    }
  }

  useEffect(() => {

    const params = {
      startDate: startDate,
      endDate: endDate
    }

    setLoading(true)
    getAllCoupons(params).then(res => {
      if (res.data.code === 200) {
        if (res.data.accessToken) setUpCookie(TOKEN, res.data.accessToken)
        couponContext.updateCouponList(res.data.data)
        setLoading(false)
      } else {
        // toast.error(res.data.message)
        setLoading(false)
      }
    }).catch(() => setLoading(false))
  }, [startDate, endDate])

  const handleSearch = (value) => {
    setSearchInput(value.toLowerCase())
  }

  useEffect(() => {
    if(searchInput) {
      const filter = couponContext.couponList?.filter(item => item.offerName.toLowerCase().includes(searchInput.toLowerCase()))
      setFilteredData(filter)
    } else {
      setFilteredData(couponContext.couponList)
    }
  }, [searchInput, couponContext.couponList])

  const handleEdit = (id) => {
    navigate(`/editCoupon/${id}`, { state: { id: id } })
  }

  const openModal = (id) => {
    setSelectedCouponId(id)
    setModal(true)
    setTimeout(() => {
      setIsModalVisible(true)
    }, 100)
  }

  const closeModal = () => {
    setIsModalVisible(false)
    setTimeout(() => {
      setModal(false)
      setSelectedCouponId(null)
    }, 300)
  }

  const handleDelete = () => {
    if (selectedCouponId) {
      const bodyData = {
        requestId: selectedCouponId,
        status: false,
      }

      deleteCoupon(bodyData).then(res => {
        if (res.data.code === 200) {
          if (res.data.accessToken) setUpCookie(TOKEN, res.data.accessToken)
          couponContext.updateCouponList(prev => prev.filter(item => item.id !== selectedCouponId))
          toast.success(res.data.message)
          closeModal()
        } else {
          toast.error(res.data.message)
          closeModal()
        }
      }).catch(() => toast.error('Failed to delete coupon'))
    }
  }

  const handleView = (id) => {
    navigate(`/viewCoupon/${id}`, { state: { id: id } })
  }
  
  return (
    <div className='px-9 py-8 h-screen overflow-auto no-scrollbar'>
      {loading && <Loader backgroundColor='transparent' />}
      <h4 className='text-base uppercase text-dark-brown font-inter font-bold'>Coupons</h4>
      <div className='mt-3 w-full h-500px bg-white rounded shadow-xl'>   
        <div className='relative'>
          <div className='p-2 grid md:flex justify-between items-center'>
            <div className='md:ms-5 mt-4 flex items-center'>
              <SearchBar placeholder="Search Coupon Code" onInputChange={handleSearch} />
              <RangePicker onChange={handleDates} />
            </div>
            <Link to='/addCoupon'><button className='mt-4 me-5 bg-dark-brown w-36 h-9 rounded text-xs text-white font-inter font-semibold border shadow-xl'>Add Coupon</button></Link>
          </div>
          <div className='mt-3 no-scrollbar max-h-96 overflow-y-auto'>
            <div className='flex flex-col space-y-2'>
              <div className='hidden md:flex bg-light-brown p-3 font-inter font-semibold text-xs text-center sticky top-0'>
                <div className='flex-1'>Coupon Title</div>
                <div className='flex-1'>Code</div>
                <div className='flex-1'>Discount</div>
                <div className='flex-1'>Maximum Amount</div>
                <div className='flex-1'>Start Date</div>
                <div className='flex-1'>End Date</div>
                <div className='flex-1'>Status</div>
                <div className='flex-1'>Action</div>               
              </div>
              <Pagination data={filteredData} justifyContent='end'>
                {filteredData?.length > 0 ? (filteredData.map((item, index) => (
                  <div key={index} className='flex flex-col md:flex-row border-b border-gray-300 py-3 space-y-2 md:space-y-0'>
                    <div title={item.offerName} onClick={() => handleView(item.id)} className='flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center underline underline-offset-2 cursor-pointer'><span className='md:hidden text-nowrap font-bold'>Coupon Title: </span>{item.offerName.length > 20 ? `${item.offerName.substring(0, 20)}...` : item.offerName}</div>
                    <div className='flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center'><span className='md:hidden text-nowrap font-bold'>Code: </span>{item.couponCode}</div>
                    <div className='flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center'><span className='md:hidden text-nowrap font-bold'>Discount: </span>{item.discount}</div>
                    <div className='flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center'><span className='md:hidden text-nowrap font-bold'>Maximum Amount: </span>{item.maximumDiscount}</div>
                    <div className='flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center'><span className='md:hidden text-nowrap font-bold'>Start Date: </span>{item.startDate}</div>
                    <div className='flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center'><span className='md:hidden text-nowrap font-bold'>End Date: </span>{item.endDate}</div>
                    <div className='flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center'><span className='md:hidden text-nowrap font-bold'>Status: </span>{item.active ? "Active" : "Disabled"}</div>              
                    <div className='flex-1 gap-2 flex px-3 md:justify-evenly items-center font-inter text-xs text-gray-500 text-center cursor-pointer'><span className='md:hidden text-nowrap font-bold'>Action: </span><div onClick={() => handleEdit(item.id)}><IoPencilSharp /></div><div onClick={() => openModal(item.id)}><IoTrashSharp className='text-red-600' /></div></div>                
                  </div>
                ))) : (
                  <div className='flex-1 text-center py-3 italic font-inter font-medium text-sm text-red-500'>No data available.</div>
                )}
              </Pagination>
            </div>
          </div>
        </div>
      </div>

      {modal && (
        <div className={`fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center transition-opacity duration-300 ${isModalVisible ? 'opacity-100' : 'opacity-0'} bg-black bg-opacity-50`}>
          <div className={`bg-white p-6 rounded-md shadow-lg transition-transform duration-300 ${isModalVisible ? 'translate-y-0' : 'translate-y-10'}`}>
            <p className='font-Inter font-semibold text-base text-justify'>Are you sure you want to delete this item?</p>
            <div className='flex justify-end space-x-4 mt-4'>
              <button onClick={() => closeModal()} className='bg-gray-300 px-3 py-2 rounded text-xs hover:bg-gray-400'>Cancel</button>
              <button onClick={handleDelete} className='bg-red-500 text-white px-3 py-2 rounded text-xs hover:bg-red-600'>Delete</button>
            </div>
          </div>
        </div>
      )}

    </div>
  )
}

export default Coupons