import { TfiDashboard } from "react-icons/tfi"
import { AiOutlineProduct } from "react-icons/ai"
import { LuShoppingCart, LuArchiveRestore } from "react-icons/lu"
import { MdOutlineInventory2, MdLogout, MdOutlineLocalOffer  } from "react-icons/md"
import { LiaFileInvoiceDollarSolid } from "react-icons/lia"
import { PiUsersThree } from "react-icons/pi"
import { BiSolidDiscount } from "react-icons/bi"
import { RiDiscountPercentFill } from "react-icons/ri"
import { FaRegUserCircle } from "react-icons/fa"

export const navLinks = [
  { id: 1, title: "Dashboard", icon: TfiDashboard, link: "/" },
  {
    id: 2,
    title: "Products",
    icon: AiOutlineProduct,
    link: "/products/getProducts",
  },
  {
    id: 3,
    title: "Orders",
    icon: LuShoppingCart,
    link: "/OrderList",
  },
  {
    id: 4,
    title: "Invoice",
    icon: LiaFileInvoiceDollarSolid,
    link: "/invoice",
  },
  {
    id: 5,
    title: "Users List",
    icon: PiUsersThree,
    link: "/userList",
  },
  {
    id: 6,
    title: "Offers",
    icon: MdOutlineLocalOffer,
    link: "/offers",
  },
  {
    id: 7,
    title: "Brands",
    icon: LuArchiveRestore,
    link: "/brands",
  },
  {
    id: 8,
    title: "Coupons",
    icon: BiSolidDiscount,
    link: "/coupons",
  },
  {
    id: 9,
    title: "Transactions",
    icon: RiDiscountPercentFill,
    link: "/transactions",
  },
  {
    id: 10,
    title: "Accounts",
    icon: FaRegUserCircle,
    link: "#",
    subMenu: true,
    subMenuItems: [
      { id: 1, title: "My Profile", link: "/profile" },
      // { id: 2, title: "Settings", link: "/settings" },
      { id: 3, title: "Reset Password", link: "/profile/reset-password" },
    ],
  },
  {
    id: 11,
    title: "Inventory",
    icon: MdOutlineInventory2,
    link: "/inventory",
  },
  { id: 12, title: "Logout", icon: MdLogout, link: "/logout" },
];


export const InvoiceData = [
  {
    id: 1,
    title: "Clients",
    totalCount: "559.25K"
  },
  {
    id: 2,
    title: "Invoices",
    totalCount: "559.25K"
  },
  {
    id: 3,
    title: "Paid",
    totalCount: "559.25K"
  },
  {
    id: 4,
    title: "Unpaid",
    totalCount: "559.25K"
  },
]