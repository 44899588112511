import { createContext, useState } from "react";

const TransactionStates = createContext()

const TransactionContext = (props) => {
    const [data, setData] = useState([])


    const GetTransactionApi = (updateTransaction) => {
        setData(updateTransaction)
    }


return (
    <TransactionStates.Provider value={{
        GetTransactionApi: GetTransactionApi,
        data: data

    }}>
    {props.children}
    </TransactionStates.Provider>
)
}

export {TransactionStates, TransactionContext}

