import { useState } from "react"
import { resetPassword } from "../../Networking/UserAction"
import Loader from "../../acsComponents/Loader"
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs"
import { setUpCookie } from "../../utils/Cookie"
import { TOKEN } from "../../utils/Constant"
import { toast } from "react-toastify"

const ResetPassword = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [type, setType] = useState('password')
    const [passwordMatch, setPasswordMatch] = useState(true)
    const [errors, setErrors] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const showPassword = () => type === 'text' ? setType('password') : setType('text')

    const handleReset = () => {
        setIsLoading(true)
        setErrors(false)
        setPasswordMatch(true)

        if (email.trim() === '' || password.trim() === '' || confirmPassword.trim() === '') {
            setErrors(true)
            setIsLoading(false)
            return
        }

        if (password !== confirmPassword) {
            setPasswordMatch(false)
            setIsLoading(false)
            return
        }

        const resetValues = () => {
            setConfirmPassword('')
            setPassword('')
            setEmail('')
            setIsLoading(false)
        }

        const bodyData = {
            email: email,
            newPassword: password,
            confirmPassword: confirmPassword
        }

        resetPassword(bodyData).then(res => {
            if (res.data.code === 200) {
                if (res.data.accessToken) setUpCookie(TOKEN, res.data.accessToken)
                toast.success(res.data.message)
                resetValues()
            } else {
                toast.error(res.data.message)
                setIsLoading(false)
            }
        })
    }

  return (
    <div  className='px-9 py-8 h-screen overflow-auto no-scrollbar'>
        {isLoading && <Loader backgroundColor='transparent' />}
        <div className='w-full bg-white px-5 py-10 shadow-md shadow-slate-300'>
            <h1 className='font-inter font-semibold text-dark-brown'>Reset Password</h1>
            <div className='px-5 mt-7 grid grid-cols-1'>
                <div className='mb-5 grid md:flex items-center'>
                    <label className='md:w-1/6 text-sm font-inter font-semibold mb-1.5'>Email</label>
                    <input type='email' value={email} onChange={(e) => setEmail(e.target.value)} className='peer md:w-1/3 border py-2 px-2 appearance-none text-gray-700 text-sm focus:outline-none bg-whitesmoke ... invalid:[&:not(:placeholder-shown):not(:focus)]:border-red-500' id='email' placeholder='eg: admin@gmail.com' pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$' />
                    <span className='hidden ms-4 text-xs text-red-600 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block'>Please enter a valid email address</span>
                    {errors && !email ? <p className='ms-4 text-red-500 text-xs'>Email is required</p> : null}
                </div>
                <div className='mb-5 grid md:flex items-center'>
                    <label className='md:w-1/6 text-sm font-inter font-semibold mb-1.5'>New Password</label>
                    <input type={type} value={password} onChange={(e) => setPassword(e.target.value)} className='peer md:w-1/3 border py-2 px-2 appearance-none text-gray-700 text-sm focus:outline-none bg-whitesmoke' placeholder='admin@123' />
                    {errors && !password ? <p className='ms-4 text-red-500 text-xs'>Password is required</p> : null}
                </div>
                <div className='grid md:flex items-center'>
                    <label className='md:w-1/6 text-sm font-inter font-semibold mb-1.5'>Confirm Password</label>
                    <input type={type} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className='peer md:w-1/3 border py-2 px-2 appearance-none text-gray-700 text-sm focus:outline-none bg-whitesmoke' placeholder='admin@123' />
                    <button onClick={showPassword} className='ms-5 text-zinc-400 hidden md:flex'>
                        {type === 'text' ? <BsEyeFill /> : <BsEyeSlashFill />}
                    </button>
                    {errors && !confirmPassword ? <p className='ms-4 text-red-500 text-xs'>Confirm your password</p> : null}
                    {!passwordMatch && <p className='ms-4 text-red-500 text-xs'>Passwords do not match</p>}
                </div>
            </div>

            <div className='mt-5 mb-5 px-4 flex justify-end'>
                <button onClick={handleReset} className='px-2 py-1.5 bg-dark-brown rounded text-xs text-white font-inter font-semibold shadow'>Change Password</button>
            </div>

        </div>
                
    </div>
  )
}

export default ResetPassword