import { AxiosConfig } from "./AxiosConfig"

export const getAllCoupons = async (params) => {
    return await AxiosConfig.post('/coupons/getCoupons', null, {
        params: {
            ...params
        }
    })
}

export const addCoupon = async (data, params) => {
    return await AxiosConfig.post('/coupons/addCoupon', data, {
        params: {
            ...params
        },
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
}

export const deleteCoupon = async (data) => {
    return await AxiosConfig.post('/coupons/deleteCoupon', data)
}

export const editcoupon = async (data, params) => {
    return await AxiosConfig.post('/coupons/editCouponValues', data, {
        params: {
            ...params
        },
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
}