import React, { useEffect, useState, useContext } from "react";
import { disableProduct, getAllInventoryProducts } from "../../Networking/InventoryApiActions";
import { InventoryStates } from "../../Context/InventoryContext";
import SearchBar from '../../acsComponents/SearchBar';
import { IoTrashSharp, IoPencilSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { setUpCookie } from "../../utils/Cookie";
import { TOKEN } from "../../utils/Constant";
import Pagination from "../../acsComponents/Pagination";
import Loader from "../../acsComponents/Loader";
import { toast } from "react-toastify";

const GetProducts = () => {
  const navigate = useNavigate();
  const inventoryContext = useContext(InventoryStates);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  
  const handleSearch = (value) => setSearchInput(value.toLowerCase());

  const filteredProducts = inventoryContext.getInventoryData?.filter(item => item.product.toLowerCase().includes(searchInput));

  const tableHeads = ["Product Name", "Product Brand", "Product Color", "Product Size", "Product Unit", "Discount Price", "Rating", "Main Image", "Action"];

  useEffect(() => {
    setLoading(true)
    getAllInventoryProducts().then((response) => {
      const data = response?.data?.data;
      if (response.data.accessToken) setUpCookie(TOKEN, response.data.accessToken);
      inventoryContext.getInventory(data); 
      setLoading(false);
    });

  }, []);

  const handleEdit = (productId, productName, imageURL, brand) => {
    navigate(`/updateAvailability/${productId}`, { state: { productId, productName, imageURL, brand } });
  };

  const openModal = (id) => {
    setSelectedID(id)
    setModal(true)
    setTimeout(() => {
      setIsModalVisible(true)
    }, 100)
  }

  const closeModal = () => {
    setIsModalVisible(false)
    setTimeout(() => {
      setModal(false)
      setSelectedID(null)
    }, 300)
  }

  const handleDelete = () => {
    if (selectedID) {
      const bodyData = { requestId: selectedID };

      disableProduct(bodyData).then(res => {
        if (res.data.code === 200) {
          if (res.data.accessToken) setUpCookie(TOKEN, res.data.accessToken);
          inventoryContext.getInventory(prev => prev.filter(item => item.productId !== selectedID));
          toast.success(res.data.message);
          closeModal();
        } else {
          toast.error(res.data.message);
          closeModal();
        }
      }).catch(() => toast.error('Failed to delete product'))
    }
  };

  return (
    <div className="px-9 py-8 font-inter h-screen overflow-auto no-scrollbar">
      {loading && <Loader backgroundColor="transparent" />}
      <h4 className='text-base uppercase text-dark-brown font-inter font-bold'>Inventory</h4>
      <div className="mt-10 md:w-2/5">
        <SearchBar placeholder="Search product Name" onInputChange={handleSearch} />
      </div>   
      <div className="w-full mt-4 h-96 no-scrollbar overflow-y-auto bg-white shadow-lg rounded-b-lg pb-4">       
        <div className="sticky top-0 ">
          <div className="hidden md:flex justify-center items-center">
            {
              tableHeads.map((heading) => (<div key={heading} className="flex-1 flex items-center justify-center bg-light-brown h-12 font-inter text-xs text-center font-medium">{heading}</div>))
            }
          </div>
        </div>
        <div>
          <Pagination data={filteredProducts} justifyContent='end'>
            {filteredProducts?.length > 0 ? (
              filteredProducts.map((content) => (
                <div key={content.id} className="flex flex-col md:flex-row border-b border-gray-300 py-3 space-y-2 md:space-y-0">
                  <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center"><span className='md:hidden text-nowrap font-bold'>Product Name: </span>{content.product ? content.product : 'N/A'}</div>
                  <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center"><span className='md:hidden text-nowrap font-bold'>Product Brand: </span>{content.brand ? content.brand : 'N/A'}</div>
                  <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center"><span className='md:hidden text-nowrap font-bold'>Product Color: </span>{content.color ? content.color : 'N/A'}</div>
                  <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center"><span className='md:hidden text-nowrap font-bold'>Product Size: </span>{content.productSize ? content.productSize : 'N/A'}</div>
                  <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center"><span className='md:hidden text-nowrap font-bold'>Product Unit: </span>{content.unit ? content.unit : 'N/A'}</div>
                  <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center"><span className='md:hidden text-nowrap font-bold'>Discount Price: </span>{content.discountedPrice ? content.discountedPrice : 'N/A'}</div>
                  <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center"><span className='md:hidden text-nowrap font-bold'>Rating: </span>{content.rating ? content.rating : 'N/A'}</div>
                  <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-gray-500 text-center">
                    <span className='md:hidden text-nowrap font-bold'>Main Image: </span><img src={content.mainImage} alt='Main Image' className="h-10 w-12" />
                  </div>                      
                  <div className='flex-1 gap-2 flex px-3 md:justify-evenly items-center font-inter text-xs text-gray-500 text-center cursor-pointer'>
                    <span className='md:hidden text-nowrap font-bold'>Action: </span>
                    <div onClick={() => handleEdit(content.productId, content.product, content.mainImage, content.brand)}>
                      <IoPencilSharp />
                    </div>
                    <div onClick={() => openModal(content.productId)}>
                      <IoTrashSharp className="text-red-600" />
                    </div>
                  </div>               
                </div>
              ))
            ) : (
              <div className="border-b py-3 flex border-border-gray-outline items-center justify-center text-red-600 italic">
                <p>No data found</p>
              </div>
            )}
          </Pagination>
        </div>   
      </div>

      {modal && (
        <div className={`fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center transition-opacity duration-300 ${isModalVisible ? 'opacity-100' : 'opacity-0'} bg-black bg-opacity-50`}>
          <div className={`bg-white p-6 rounded-md shadow-lg transition-transform duration-300 ${isModalVisible ? 'translate-y-0' : 'translate-y-10'}`}>
            <p className='font-Inter font-semibold text-base text-justify'>Are you sure you want to delete this item?</p>
            <div className='flex justify-end space-x-4 mt-4'>
              <button onClick={() => closeModal()} className='bg-gray-300 px-3 py-2 rounded text-xs hover:bg-gray-400'>Cancel</button>
              <button onClick={handleDelete} className='bg-red-500 text-white px-3 py-2 rounded text-xs hover:bg-red-600'>Delete</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default GetProducts