import { CloseButton, Download } from "../../assets/icons"


const ViewTransaction = () => {
  return (
    <div className='px-4 py-10 sm:px-9'>
        <div className='mt-3 w-full bg-white rounded shadow-lg'>
            <div className='px-10 py-8'>
                <div className='mt-2 flex justify-between'>
                    <h4 className='text-xs font-inter font-bold ms-6'>Payment Details</h4>
                    <div className='flex flex-wrap gap-10 justify-end me-6'>
                        <button className='flex justify-center items-center gap-1 py-1.5 px-2.5 rounded-md bg-dark-brown text-white hover:text-white hover:bg-pink-700'>
                            <img src={Download} width={12} height={12} alt="Download" />
                            <label className='text-xs font-semibold cursor-pointer'>Download Receipt</label>
                        </button>
                        <img src={CloseButton} width={12} height={12} className='cursor-pointer' alt="Close-Button" />
                    </div>
                </div>
                
                <div className='mt-4 space-y-5 pb-20 ms-6'>
                    <div className='flex justify-between'>
                        <p className='font-inter text-sm text-gray-500 font-medium w-48'>TRANSACTION ID</p>
                        <p className='flex-1 ms-3 text-sm font-inter font-semibold'>Dummy</p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='font-inter text-sm text-gray-500 font-medium w-48'>DATE</p>
                        <p className='flex-1 ms-3 text-sm font-inter font-semibold'>Dummy</p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='font-inter text-sm text-gray-500 font-medium w-48'>TAX ID</p>
                        <p className='flex-1 ms-3 text-sm font-inter font-semibold'>Dummy</p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='font-inter text-sm text-gray-500 font-medium w-48'>CUSTOMER NAME</p>
                        <p className='flex-1 ms-3 text-sm font-inter font-semibold'>Dummy</p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='font-inter text-sm text-gray-500 font-medium w-48'>EMAIL ID</p>
                        <p className='flex-1 ms-3 text-sm font-inter font-semibold'>Dummy</p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='font-inter text-sm text-gray-500 font-medium w-48'>AMOUNT</p>
                        <p className='flex-1 ms-3 text-sm font-inter font-semibold'>Dummy</p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='font-inter text-sm text-gray-500 font-medium w-48'>PAYMENT METHOD</p>
                        <p className='flex-1 ms-3 text-sm font-inter font-semibold'>Dummy</p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='font-inter text-sm text-gray-500 font-medium w-48'>STATUS</p>
                        <p className='flex-1 ms-3 text-sm font-inter font-semibold'>Dummy</p>
                    </div>
                </div>
            </div>
        </div>


        
    </div>
  )
}

export default ViewTransaction