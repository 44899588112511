import { useState } from "react";
import { addProductSize } from "../../Networking/ProductApisActions";
import { useNavigate } from "react-router-dom";
import Loader from "../../acsComponents/Loader";
import { setUpCookie } from "../../utils/Cookie";
import { TOKEN } from "../../utils/Constant";
import { toast } from "react-toastify";

const AddProductSize = () => {
  const navigate = useNavigate()
  const [size, setSize] = useState("");
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const validateForm = () => {
    let formErrors = {};
    if (!size) {
        formErrors.size = "Size is required.";
    }   
    setErrors(formErrors);
      return Object.keys(formErrors).length === 0;
  }  

  const handleSizeSubmit = (e) => {
    setLoading(true)
    e.preventDefault();
    if (!validateForm()) return;
    const productData = {
      name: size,
    };
    
    addProductSize(productData).then(response => {
      if(response.data.code === 200) {
        if (response.data.accessToken) setUpCookie(TOKEN, response.data.accessToken)
        toast.success(response.data.message)
        setLoading(false)
        setSize("")
      } else {
        toast.error(response.data.message)
        setLoading(false)
      }
    })
  }; 

  return (
    <>
    <div className=" overflow-y-auto no-scrollbar h-screen font-inter">
    {loading && <Loader backgroundColor='transparent' />}
      <div className="flex justify-center items-center w-full h-screen">
        <div className="w-96 h-auto">
          <div className="flex justify-between w-full pl-2 pr-2 pb-2">
            <p className="text-dark-brown">Add Product Size</p>
          </div>
          <div className="w-full h-auto bg-white p-6">
            <form onSubmit={handleSizeSubmit}>            
              <label>Product Size</label>
              <input
                type="text"
                className="w-full p-2 border outline-none"
                name="size"
                onChange={(e) => {setSize(e.target.value)}}
                value={size}                
              />
              {errors.size && <p className='text-xs text-red-600'>{errors.size}</p>}
              <div>                        
              </div>
              <button
                type="submit"
                className="bg-darkbrown border px-3 py-1 mt-8 bg-dark-brown text-white"
              >
                Save
              </button>
              <button
                type="button"
                className="border-2 ml-2 px-3 py-1 hover:bg-dark-brown hover:text-white rounded-sm cursor-pointer"
                onClick={() => {
                  navigate(-1)
                }}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default AddProductSize;
