import { AxiosConfig } from "./AxiosConfig";

export const getAllInventoryProducts = async () => {
    return await AxiosConfig.post("/products/getAllProducts", {});
  };
  

  export const getAllAvailabiltyApi = async() => {
    return await AxiosConfig.post('/inventory/getAllAvailabilityStatus')
  }

  export const updateAvailabilityApi = async(bodyData) => {
    return await AxiosConfig.post('/inventory/updateAvailabilityStatus',
        bodyData
    )
  }

  export const disableProduct = async (data) => {
    return await AxiosConfig.post('/inventory/disableProduct', data)
  }
