import React, { useEffect, useState, useContext } from "react";
import { getAllAddedProducts } from "../../Networking/ProductApisActions";
import { ProductStates } from "../../Context/ProductContext";
import SearchBar from '../../acsComponents/SearchBar'
import Pagination from "../../acsComponents/Pagination";
import { Link } from "react-router-dom";
import Loader from '../../acsComponents/Loader'
import { setUpCookie } from "../../utils/Cookie";
import { TOKEN } from "../../utils/Constant";

const GetProducts = () => {
  const productContext = useContext(ProductStates);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSearch = (value) => setSearchInput(value.toLowerCase())
  const filteredProducts = productContext.getproducts?.filter(item => item.product?.toLowerCase().includes(searchInput))

  const tableHeads = ["Product Name","Product Brand", "Product Color", "Product Size", "Product Unit", "Discount Price", "Rating", "Main Image",]

  useEffect(() => {
    setLoading(true)
    getAllAddedProducts().then((response) => {
      const data = response.data.data;
      productContext.getProductList(data);
      if (response.data.accessToken) setUpCookie(TOKEN, response.data.accessToken)
      setLoading(false);
    }).catch(() => setLoading(false))
  }, []);

  return <div className='px-9 py-8 h-screen overflow-auto no-scrollbar'>
    {loading && <Loader backgroundColor='transparent' />}
    <h4 className='text-base text-dark-brown font-inter font-bold uppercase'>Product List</h4>
    <div className='flex justify-between items-center'>
      <div className='mt-4 w-96'>
        <SearchBar placeholder="Search product Name" onInputChange={handleSearch} />
      </div>
      <Link to='/products/AddProduct'><button className='mt-4 bg-dark-brown w-36 h-9 rounded text-xs text-white font-inter font-semibold border shadow-xl'>Add Product</button></Link>
    </div>
    <div className="w-full mt-4 no-scrollbar overflow-y-auto bg-white shadow-lg rounded-b-lg h-96">       
      <div className="sticky top-0 ">
        <div className="flex justify-center items-center">
          {tableHeads.map((heading) => (
            <div key={heading} className="hidden md:flex flex-1 items-center justify-center bg-light-brown h-12 font-inter text-xs text-center font-medium">{heading}</div>
          ))}              
        </div>
      </div>
      <div>
        <Pagination data={filteredProducts} justifyContent='end'>
          {filteredProducts?.length > 0 ? (
            filteredProducts.map(content => (
              <div key={content.id} className="flex flex-col md:flex-row border-b border-gray-300 py-2.5 gap-y-2" >
                <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-dark-brown text-center"><span className="md:hidden text-nowrap">Product Name: </span>{content.product || content.product.length > 15 ? `${content.product.substring(0, 15)}...` : 'N/A'}</div>
                <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-center"><span className="md:hidden text-nowrap">Product Brand: </span> {content.brand ? content.brand : 'N/A'}</div>
                <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-center"><span className="md:hidden text-nowrap">Product Color: </span> {content.color ? content.color : 'N/A'}</div>
                <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-center"><span className="md:hidden text-nowrap">Product Size: </span> {content.productSize ? content.productSize : 'N/A'}</div>
                <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-center"><span className="md:hidden text-nowrap">Product Unit: </span> {content.unit ? content.unit : 'N/A'}</div>
                <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-center"><span className="md:hidden text-nowrap">Discount Price: </span> {content.discountedPrice ? content.discountedPrice : 'N/A'}</div>
                <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-center"><span className="md:hidden text-nowrap">Rating: </span> {content.rating ? content.rating : 'N/A'}</div>
                <div className="flex-1 gap-2 flex px-3 md:justify-center items-center font-inter text-xs text-center"><span className="md:hidden text-nowrap">Main Image: </span><img src={content.mainImage} alt='MainImage' className="h-9"/></div>        
              </div>
              ))) : <div className="border-b  h-16 flex border-border-gray-outline justify-center items-center text-red-600 italic">No products found</div>
          }
        </Pagination>            
      </div>        
    </div>      
  </div>;
};
export default GetProducts;
