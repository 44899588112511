import { AxiosConfig } from "./AxiosConfig";

export const getGraphData = async() => {
    return await AxiosConfig.post('/dashBoard/getGraph')
}

export const getDashboard = async (params) => {
    return await AxiosConfig.post('/dashBoard/dashBoardResult', null, {
        params: {
            ...params
        }
    })
}